
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { RootStateType, AppDispatchType} from "..";

import { Input, Row, Col, Divider, Button } from "antd";

import '../css/role_editor.css';

const { TextArea } = Input;

function RoleEditor() {

    let isLoggedIn: boolean = useSelector<RootStateType, boolean>(state => state.auth.isLoggedIn);

    let authToken: string|null = useSelector<RootStateType, string|null>((state) => {
        let authToken = state.auth.authToken;

        if(!authToken){
            let sessAuthToken = sessionStorage.getItem("authToken");
            if(sessAuthToken){
                authToken = sessAuthToken;
            }
        }

        return authToken;
    });


    const [oldSysRole1, setOldSysRole1] = useState('');
    const [oldSysRole2, setOldSysRole2] = useState('');
    const [oldSysRole3, setOldSysRole3] = useState('');
    const [oldXPath, setOldXPath] = useState('');
    const [oldCSSelector, setOldCSSelector] = useState('');

    const [sysRole1, setSysRole1] = useState('');
    const [sysRole2, setSysRole2] = useState('');
    const [sysRole3, setSysRole3] = useState('');
    const [XPath, setXPath] = useState('');
    const [CSSelector, setCSSelector] = useState('');

    // const [SR1PlaceHolder, setSR1PlaceHolder] = useState('작은 따옴표는 \\\'로 입력해주세요.');
    // const [SR2PlaceHolder, setSR2PlaceHolder] = useState('작은 따옴표는 \\\'로 입력해주세요.');
    // const [XPathPlaceHolder, setXPathPlaceHolder] = useState('');
    const [sysRole1Msg, setSysRole1Msg] = useState('*공란=기존 설정 유지. 작은 따옴표는 \\\'로 입력해주세요.');
    // const [sysRole1Msg, setSysRole1Msg] = useState('*No input=keep previous role. Please input \\\' when you use small quotation mark \'');
    const [sysRole2Msg, setSysRole2Msg] = useState('*공란=기존 설정 유지. 작은 따옴표는 \\\'로 입력해주세요.');
    // const [sysRole2Msg, setSysRole2Msg] = useState('*No input=keep previous role. Please input \\\' when you use small quotation mark \'');
    const [sysRole3Msg, setSysRole3Msg] = useState('*공란=기존 설정 유지. 작은 따옴표는 \\\'로 입력해주세요.');
    const [XPathMsg, setXPathMsg] = useState('*공란=기존 설정 유지. 작은 따옴표는 \\\'로 입력해주세요.');

    const [displayLangLabel, setDisplayLangLabel] = useState('현재 표시 언어');
    const [selectedUserLang, setSelectedUserLang] = useState(() => {
        const currentLanguage = navigator.language.toLowerCase();

        const langs = ['en', 'ko'];
        let regEx = new RegExp(langs[0], 'gi');
        let selected = langs[0];

        for (let i=0; i < langs.length; i++){
            regEx = new RegExp(langs[i], 'gi');
            if (regEx.test(currentLanguage)){
                selected = langs[i];
                break;
            }
        }

        // console.log('role_editor.tsx.selectedUserLang.init:', selected);
        return selected;
    });

    function onChangeSysRole1Handler(event: React.ChangeEvent<HTMLTextAreaElement>) {
        event.preventDefault();

        setSysRole1(event.currentTarget.value);
    }

    function onChangeSysRole2Handler(event: React.ChangeEvent<HTMLTextAreaElement>) {
        event.preventDefault();

        setSysRole2(event.currentTarget.value);
    }

    function onChangeSysRole3Handler(event: React.ChangeEvent<HTMLTextAreaElement>) {
        event.preventDefault();

        setSysRole3(event.currentTarget.value);
    }

    function onChangeXPathHandler(event: React.ChangeEvent<HTMLTextAreaElement>) {
        event.preventDefault();

        setXPath(event.currentTarget.value);
    }

    function onChangeCSSelectorHandler(event: React.ChangeEvent<HTMLTextAreaElement>) {
        event.preventDefault();

        setCSSelector(event.currentTarget.value);
    }
    
    async function updateRoles() {
        let headerData = new Headers();
        let formData = new FormData();
        let retVal = {"success": false, "updateState1": 'keep', "updateState2": 'keep', "updateState3": 'keep'};

        if(authToken) {
            headerData.set("authToken", authToken);

            // let browserLang = navigator.language.toLowerCase();
            let speech = '0'; // 0: English

            const langs = ['en', 'ko'];
            let regEx = new RegExp(langs[0], 'gi');

            for (let i=0; i < langs.length; i++) {
                regEx = RegExp(langs[i], 'gi')
                // if (regEx.test(browserLang)) {
                if (regEx.test(selectedUserLang)) {
                    speech = i.toString();
                    break;
                }
            }

            console.log('role_editor.tsx.updateRoles.speech:', speech);

            headerData.set("speech", speech);

            formData.set("sysrole1", sysRole1);
            formData.set("sysrole2", sysRole2);
            formData.set("sysrole3", sysRole3);

            try {
                const response = await fetch('/api/role/update', {
                    method: 'POST'
                    , headers: headerData
                    , body: formData
                });

                const resBody = await response.json();

                // if(resBody["success"] === true){
                //     retVal = true;
                // }
                retVal = resBody;
            }
            catch (error) {
                console.log('[Error]fetch(/api/role/update)', error);
            }
        }

        console.log('fetch(/api/role/update).retVal:', retVal);

        return retVal;
    }

    async function updateXPath() {
        let headerData = new Headers();
        let formData = new FormData();
        let retVal = {"success": false, "state": 'keep'};

        if(authToken) {
            headerData.set("authToken", authToken);

            formData.set("XPath", XPath);
            formData.set("CSSelector", CSSelector);

            try {
                const response = await fetch('/api/xpath/update', {
                    method: 'POST'
                    , headers: headerData
                    , body: formData
                });

                const resBody = await response.json();

                // if(resBody["success"] === true){
                //     retVal = true;
                // }
                retVal = resBody;
            }
            catch (error) {
                console.log('[Error]fetch(/api/xpath/update)', error);
            }
        }

        console.log('fetch(/api/xpath/update).retVal:', retVal);

        return retVal;
    }

    async function onSubmitHandler(event: React.MouseEvent<HTMLElement>) {
        event.preventDefault();

        const sysRoleUpdateResult = await updateRoles();
        const XPathUpdateResult = await updateXPath();

        if(sysRoleUpdateResult["success"] === true){
            getSysRoles();
            setSysRole1('');
            setSysRole2('');
            setSysRole3('');
            console.log('System roles are updated successfully.');
            // setSR1PlaceHolder('시스템 롤을 업데이트 하였습니다.');
            // setSR2PlaceHolder('시스템 롤을 업데이트 하였습니다.');
            if(sysRoleUpdateResult["updateState1"] === 'updated'){
                setSysRole1Msg('시스템 롤1을 업데이트하였습니다.');
            }
            else{
                setSysRole1Msg('시스템 롤1은 기존 설정을 유지합니다.');
            }

            if(sysRoleUpdateResult["updateState2"] === 'updated'){
                setSysRole2Msg('시스템 롤2를 업데이트하였습니다.');
            }
            else{
                setSysRole2Msg('시스템 롤2는 기존 설정을 유지합니다.');
            }

            if(sysRoleUpdateResult["updateState3"] === 'updated'){
                setSysRole3Msg('시스템 롤3을 업데이트하였습니다.');
            }
            else{
                setSysRole3Msg('시스템 롤3는 기존 설정을 유지합니다.');
            }
        }

        if(XPathUpdateResult["success"] === true){
            getXPath();
            setXPath('');
            setCSSelector('');
            console.log('XPaths are updated successfully.');
            // setXPathPlaceHolder('XPath를 업데이트 하였습니다.');
            if(XPathUpdateResult["state"] === 'updated'){
                setXPathMsg('XPath를 업데이트하였습니다.');
            }
            else{
                setXPathMsg('XPath는 기존 설정을 유지합니다.');
            }
        }
    }

    async function getSysRoles() {
        let headerData = new Headers();

        if(authToken){
            headerData.set("authToken", authToken);

            // let browserLang = navigator.language.toLowerCase();
            let speech = '0'; // 0: English

            const langs = ['en', 'ko'];
            let regEx = new RegExp(langs[0], 'gi');

            for (let i=0; i < langs.length; i++) {
                regEx = RegExp(langs[i], 'gi')
                // if (regEx.test(browserLang)) {
                if (regEx.test(selectedUserLang)) {
                    speech = i.toString();
                    break;
                }
            }

            console.log('role_editor.tsx.getSysRoles().speech:', speech);

            headerData.set("speech", speech);

            try{
                const response = await fetch('/api/role/edit', {
                    method: 'GET'
                    , headers: headerData 
                });

                const resBody = await response.json();

                if(resBody["success"] === true) {
                    setOldSysRole1(resBody["sysrole1"]);
                    setOldSysRole2(resBody["sysrole2"]);
                    setOldSysRole3(resBody["sysrole3"]);
                }
            }
            catch (error) {
                console.log('[Error]fetch(/api/role/edit)', error);
            }
        }
    }

    async function getXPath() {
        let headerData = new Headers();

        if(authToken){
            headerData.set("authToken", authToken);

            try{
                const response = await fetch('/api/xpath/edit', {
                    method: 'GET'
                    , headers: headerData 
                });

                const resBody = await response.json();

                if(resBody["success"] === true) {
                    setOldXPath(resBody["XPath"]);
                    setOldCSSelector(resBody["CSSelector"]);
                }
            }
            catch (error) {
                console.log('[Error]fetch(/api/xpath/edit)', error);
            }
        }
    }

    function setDefaultLanguage() {
        const currentLanguage = navigator.language.toLowerCase();
        // console.log('role_editor.tsx.setDefaultLanguage.currentLanguage:', currentLanguage);

        const langs = ['en', 'ko'];
        let regEx = new RegExp(langs[0], 'gi');

        for (let i=0; i < langs.length; i++){
            regEx = new RegExp(langs[i], 'gi');
            if (regEx.test(currentLanguage)){
                // setSelectedUserLang((prevState) => {
                //     return langs[i];
                // });
                setSelectedUserLang(langs[i]);
                // console.log('role_editor.tsx.setDefaultLanguage.selected:', langs[i]);
                break;
            }
        }
    }

    function onChangeDisplayLangHandler(event: React.ChangeEvent<HTMLSelectElement>) {
        event.preventDefault();

        const newVal = event.target.value;
        // console.log('role_editor.tsx.onChangeDisplayLangHandler.newVal:', newVal);

        // setSelectedUserLang((prevState) => {
        //     return newVal;
        // });
        setSelectedUserLang(newVal);
        // getSysRoles();
        // getXPath();
    }

    useEffect(() => {
        setDefaultLanguage();
        getSysRoles();
        getXPath();
        // console.log('role_editor.tsx.useEffect().selectedUserLang:', selectedUserLang);
    }, []);

    useEffect(() => {
        getSysRoles();
        getXPath();
        // console.log('role_editor.tsx.useEffect2().selectedUserLang:', selectedUserLang);
    }, [selectedUserLang]);

    return(
        <div id="role_editor_top_container_div">
            <Row id="current_display_lang_div">
                <Col
                    xs={{span: 24}} sm={{span: 24}} md={{span: 24}}
                    lg={{span: 24}} xl={{span: 24}} xxl={{span: 24}}               
                >
                    <label htmlFor="lang_select">{displayLangLabel}</label>
                    <select id="lang_select" value={selectedUserLang} onChange={onChangeDisplayLangHandler}>
                        <option value="en">English</option>
                        <option value="ko">한국어</option>
                    </select>
                </Col>
            </Row>
            <Row id="old_system_role1_title_div">
                <Col
                    xs={{span: 24}} sm={{span: 24}} md={{span: 24}}
                    lg={{span: 24}} xl={{span: 24}} xxl={{span: 24}}               
                >
                    <h3>현재 시스템 롤 1</h3>
                    {/* <h3>Current system role 1</h3> */}
                </Col>
            </Row>
            <Row id="old_system_role1_display_div">
                <Col
                    xs={{span: 24}} sm={{span: 24}} md={{span: 24}}
                    lg={{span: 24}} xl={{span: 24}} xxl={{span: 24}}               
                >
                    <div><pre>{oldSysRole1}</pre></div>
                </Col>
            </Row>
            <Row id="system_role1_input_div">
                <Col
                    xs={{span: 24}} sm={{span: 24}} md={{span: 24}}
                    lg={{span: 24}} xl={{span: 24}} xxl={{span: 24}}               
                >
                    <TextArea showCount maxLength={1000} onChange={onChangeSysRole1Handler} value={sysRole1} autoSize={true}></TextArea>
                </Col>
            </Row>
            <Row id="system_role1_msg_div">
                <Col
                    xs={{span: 24}} sm={{span: 24}} md={{span: 24}}
                    lg={{span: 24}} xl={{span: 24}} xxl={{span: 24}}               
                >
                    {sysRole1Msg}
                </Col>
            </Row>
            <Divider></Divider>
            <Row id="old_system_role2_title_div">
                <Col
                    xs={{span: 24}} sm={{span: 24}} md={{span: 24}}
                    lg={{span: 24}} xl={{span: 24}} xxl={{span: 24}}               
                >
                    <h3>현재 시스템 롤 2</h3>
                    {/* <h3>Current system role 2</h3> */}
                </Col>
            </Row>
            <Row id="old_system_role2_display_div">
                <Col
                    xs={{span: 24}} sm={{span: 24}} md={{span: 24}}
                    lg={{span: 24}} xl={{span: 24}} xxl={{span: 24}}               
                >
                    <div><pre>{oldSysRole2}</pre></div>
                </Col>
            </Row>
            <Row id="system_role2_input_div">
                <Col
                    xs={{span: 24}} sm={{span: 24}} md={{span: 24}}
                    lg={{span: 24}} xl={{span: 24}} xxl={{span: 24}}               
                >
                    <TextArea showCount maxLength={1000} onChange={onChangeSysRole2Handler} value={sysRole2} autoSize={true}></TextArea>
                </Col>
            </Row>
            <Row id="system_role2_msg_div">
                <Col
                    xs={{span: 24}} sm={{span: 24}} md={{span: 24}}
                    lg={{span: 24}} xl={{span: 24}} xxl={{span: 24}}               
                >
                    {sysRole2Msg}
                </Col>
            </Row>
            <Divider></Divider>
            <Row id="old_system_role3_title_div">
                <Col
                    xs={{span: 24}} sm={{span: 24}} md={{span: 24}}
                    lg={{span: 24}} xl={{span: 24}} xxl={{span: 24}}               
                >
                    <h3>현재 시스템 롤 3</h3>
                    {/* <h3>Current system role 3</h3> */}
                </Col>
            </Row>
            <Row id="old_system_role3_display_div">
                <Col
                    xs={{span: 24}} sm={{span: 24}} md={{span: 24}}
                    lg={{span: 24}} xl={{span: 24}} xxl={{span: 24}}               
                >
                    <div><pre>{oldSysRole3}</pre></div>
                </Col>
            </Row>
            <Row id="system_role3_input_div">
                <Col
                    xs={{span: 24}} sm={{span: 24}} md={{span: 24}}
                    lg={{span: 24}} xl={{span: 24}} xxl={{span: 24}}               
                >
                    <TextArea showCount maxLength={1000} onChange={onChangeSysRole3Handler} value={sysRole3} autoSize={true}></TextArea>
                </Col>
            </Row>
            <Row id="system_role3_msg_div">
                <Col
                    xs={{span: 24}} sm={{span: 24}} md={{span: 24}}
                    lg={{span: 24}} xl={{span: 24}} xxl={{span: 24}}               
                >
                    {sysRole3Msg}
                </Col>
            </Row>
            <Divider></Divider>
            <Row id="old_xpath_title_div">
                <Col
                    xs={{span: 24}} sm={{span: 24}} md={{span: 24}}
                    lg={{span: 24}} xl={{span: 24}} xxl={{span: 24}}               
                >
                    <h3>현재 시스템 XPath - CSSelector</h3>
                </Col>
            </Row>
            <Row id="old_xpath_display_div">
                <Col
                    xs={{span: 24}} sm={{span: 24}} md={{span: 24}}
                    lg={{span: 24}} xl={{span: 24}} xxl={{span: 24}}               
                >
                    <div><pre>{oldXPath}</pre></div>
                </Col>
            </Row>
            <Row id="xpath_input_div">
                <Col
                    xs={{span: 24}} sm={{span: 24}} md={{span: 24}}
                    lg={{span: 24}} xl={{span: 24}} xxl={{span: 24}}               
                >
                    <TextArea showCount maxLength={500} onChange={onChangeXPathHandler} value={XPath} autoSize={true}></TextArea>
                </Col>
            </Row>
            <Row id="old_csselector_display_div">
                <Col
                    xs={{span: 24}} sm={{span: 24}} md={{span: 24}}
                    lg={{span: 24}} xl={{span: 24}} xxl={{span: 24}}               
                >
                    <div><pre>{oldCSSelector}</pre></div>
                </Col>
            </Row>
            <Row id="csselector_input_div">
                <Col
                    xs={{span: 24}} sm={{span: 24}} md={{span: 24}}
                    lg={{span: 24}} xl={{span: 24}} xxl={{span: 24}}               
                >
                    <TextArea showCount maxLength={500} onChange={onChangeCSSelectorHandler} value={CSSelector} autoSize={true}></TextArea>
                </Col>
            </Row>
            <Row id="xpath_msg_div">
                <Col
                    xs={{span: 24}} sm={{span: 24}} md={{span: 24}}
                    lg={{span: 24}} xl={{span: 24}} xxl={{span: 24}}               
                >
                    {XPathMsg}
                </Col>
            </Row>
            <Divider></Divider>
            <Row id="role_editor_button_div" justify={'center'}>
                <Col
                    xs={{span: 24}} sm={{span: 24}} md={{span: 24}}
                    lg={{span: 24}} xl={{span: 24}} xxl={{span: 24}}               
                >
                    {/* <Button type="primary" onClick={onSubmitHandler}>제출</Button> */}
                    <Button type="primary" onClick={onSubmitHandler}>Submit</Button>
                </Col>
            </Row>
        </div>
    );

}

export default RoleEditor;
