import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// import { signoutUser } from '../reducers/user_auth';
import { signoutUser, removeAuth } from '../features/user_auth';
import { RootStateType, AppDispatchType } from '..';
import { useState } from 'react';
// import { resetTopicListInfo } from '../features/topic_list_info';
// import { resetNoteListInfo } from '../features/note_list_info';
// import { resetHubListInfo } from '../features/hub_list_info';
// import { resetDiscussionListInfo } from '../features/discussion_list_info';
// import { resetPrevPageInfo } from '../features/prev_page_info';
// import { resetAgoraInfo } from '../features/agora_info';

import { Row, Col, Menu, Drawer, Button, Switch } from 'antd';
import { ApiOutlined, CommentOutlined, LoginOutlined, LogoutOutlined, MenuOutlined, FormOutlined, TeamOutlined, UserOutlined } from '@ant-design/icons';
// import MenuItem from 'antd/lib/menu/MenuItem';

import '../css/navbar.css';
import minitee_logo from '../resources/image/minitee_layout_top_logo_outlined.svg';
import { SwitchChangeEventHandler } from 'antd/lib/switch';

function NavBar(): JSX.Element {

    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatchType>();

    const isLoggedIn: boolean = useSelector<RootStateType, boolean>(state => state.auth.isLoggedIn);
    const authToken: string|null = useSelector<RootStateType, string|null>(state => state.auth.authToken);

    const [currentItem, setCurrentItem] = useState('/home');
    const [visible, setVisible] = useState(false);

    const [items, setItems] = useState<Array<any>>([
        {label: 'Signup', key: '/user/signup', icon: <ApiOutlined/>}
        , {label: 'Signin', key: '/user/signin', icon: <LoginOutlined/>}
    ]);

    const [darkMode, setDarkMode] = useState<boolean>(false);
 
    // const onGoHomeHandler = (event: React.MouseEvent<HTMLAnchorElement>) => {
    //     event.preventDefault();
    //     navigate('/home');
    // }

    // const onSignupHandler = (event: React.MouseEvent<HTMLAnchorElement>) => {
    //     event.preventDefault();
    //     navigate('/user/signup');
    // }

    // const onSigninHandler = (event: React.MouseEvent<HTMLAnchorElement>) => {
    //     event.preventDefault();
    //     navigate('/user/signin');
    // }

    // const onSignoutHandler = (event: React.MouseEvent<HTMLAnchorElement>) => {
    //     event.preventDefault();
    //     dispatch(signoutUser(authToken));
    //     navigate('/home');
    // }    

    // const onDisplayMyinfoHandler = (event: React.MouseEvent<HTMLAnchorElement>) => {
    //     event.preventDefault();
    //     navigate('/user/myinfo');
    // }

    const showDrawer = () => {
        setVisible(true);
    }

    const hideDrawer = () => {
        setVisible(false);
    }

    const onPageHandler = (event: React.MouseEvent<HTMLAnchorElement>) => {
        event.preventDefault();
        let page = event.currentTarget.dataset.page;
        setVisible(false);
        // if(page==='home' && isLoggedIn){
        //     page = 'friends/list';
        // }
        setCurrentItem('');
        navigate(`/${page}`);
    };

    const onHandleMenuClick = (event: any) => {
        setCurrentItem(event.key);
        let page = event.key;
        setVisible(false);

        // console.log('navbar.onHandleMenuClick:', page);

        if(page != '/user/signout'){
            navigate(page);
        }
        else{
            // dispatch(signoutUser(authToken));
            dispatch(signoutUser());
            removeAuth(authToken);
            // dispatch(resetTopicListInfo());
            // dispatch(resetNoteListInfo());
            // dispatch(resetHubListInfo());
            // dispatch(resetDiscussionListInfo());
            // dispatch(resetPrevPageInfo());
            // dispatch(resetAgoraInfo());
            navigate('/home');
        }
    };

    function onChangeColorSchemeHandler(checked: boolean) {
        // console.log('navbar.tsx.onChangeColorSchemeHandler().checked:', checked);

        const docBody = document.querySelector("body");

        if (docBody && docBody.classList.contains("dark_mode")) {
            docBody.classList.remove("dark_mode");
            localStorage.removeItem('color_mode');
            setDarkMode(false);
            // console.log('navbar.tsx.onChangeColorSchemeHandler(): dark_mode class removed.');
        }
        else {
            if(docBody){
                docBody.classList.add("dark_mode");
                localStorage.setItem("color_mode", 'true');
                setDarkMode(true);
                // console.log('navbar.tsx.onChangeColorSchemeHandler(): dark_mode class added.');
            }
        }

        // if(docBody){
        //     console.log('navbar.tsx.onChangeColorSchemeHandler().docBody.classList:', docBody.classList);
        //     console.log('navbar.tsx.onChangeColorSchemeHandler().isDarkMode:', localStorage.getItem("color_mode"));        
        // }
    }

    useEffect(() => {
        if(isLoggedIn){
            // setCurrentItem('/topics');
            // setItems([
            //     {label: '노트목록', key: '/topics', icon: <FormOutlined/>}
            //     , {label: '친구목록', key: '/friends', icon: <TeamOutlined/>}
            //     , {label: '의견', key: '/agora', icon: <CommentOutlined/>}
            //     , {label: 'Signout', key: '/user/signout', icon: <LogoutOutlined/>}
            // ]);
            setCurrentItem('/home');
            setItems([
                {label: 'Signout', key: '/user/signout', icon: <LogoutOutlined/>}
                , {label: 'MyInfo', key: '/user/myinfo', icon: <UserOutlined/>}
            ])
        }
        else{
            setItems([
                {label: 'Signup', key: '/user/signup', icon: <ApiOutlined/>}
                , {label: 'Signin', key: '/user/signin', icon: <LoginOutlined/>}
            ]);
        }

        const wasDarkMode = localStorage.getItem("color_mode") === 'true' ? true : false;
        const docBody = document.querySelector("body");

        // console.log('navbar.tsx.useEffect().wasDarkMode:', wasDarkMode);

        if(docBody) {
            if(wasDarkMode) {
                if(!docBody.classList.contains("dark_mode")){
                    docBody.classList.add("dark_mode");
                }
                setDarkMode(true);
                // console.log('navbar.tsx.useEffect().setDarkMode:', wasDarkMode);
            }
            else {
                docBody.classList.remove("dark_mode");
                setDarkMode(false);
                // console.log('navbar.tsx.useEffect().setDarkMode:', wasDarkMode);
            }        
        }

        // console.log('navbar.useEffect.isLoggedIn:', isLoggedIn);
    }, [isLoggedIn]);

    return(
        <div className="navbar_div">
            <Row>
                <Col
                     xs={{span: 1}} sm={{span: 1}} md={{span: 1}}
                     lg={{span: 4}} xl={{span: 4}} xxl={{span: 6}}
                >
                </Col>
                <Col
                     xs={{span: 22}} sm={{span: 22}} md={{span: 22}}
                     lg={{span: 16}} xl={{span: 16}} xxl={{span: 12}}
                >
                    <div className="mobile_hidden">
                        <div className="desk_nav">
                            <div className="navbar_logo_div">
                                <a href="" data-page="home" onClick={onPageHandler}>
                                    <img src={minitee_logo} data-page="home" alt="Logo"></img>
                                </a>
                            </div>
                            <div className="navbar_menu_div">
                                <Menu items={items} mode="horizontal" onClick={onHandleMenuClick} selectedKeys={[currentItem]}></Menu>
                            </div>
                            <div className="navbar_color_scheme_div">
                                <Switch onChange={onChangeColorSchemeHandler} checkedChildren="밝게" unCheckedChildren="어둡게" checked={darkMode}/>
                            </div>
                        </div>
                    </div>
                    <div className="mobile_visible">
                        <div className="mobile_nav">
                            <div className="navbar_logo_div">
                                <a href="" data-page="home" onClick={onPageHandler}>
                                    <img src={minitee_logo} data-page="home" alt="Logo"></img>
                                </a>
                            </div>
                            <div className="navbar_color_scheme_div">
                                <Switch onChange={onChangeColorSchemeHandler} checkedChildren="밝게" unCheckedChildren="어둡게" checked={darkMode}/>
                            </div>
                            <Button type="primary" onClick={showDrawer} icon={<MenuOutlined/>}></Button>
                            <Drawer placement="right" closable={false} onClose={hideDrawer} open={visible} width="200px">
                                <Menu items={items} onClick={onHandleMenuClick} selectedKeys={[currentItem]}></Menu>
                            </Drawer>
                        </div>
                    </div>
                </Col>
                <Col
                     xs={{span: 1}} sm={{span: 1}} md={{span: 1}}
                     lg={{span: 4}} xl={{span: 4}} xxl={{span: 6}}               
                >
                </Col>
            </Row>
        </div>
    );

}

export default NavBar;
