import React, { ChangeEvent, useEffect, useState } from "react";

import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { RootStateType } from "..";

import { Row, Col, Divider, Button, Input, Select } from "antd";

import AppLayout from "../components/app_layout";

import "../css/manage_notice.css";


function ManageNotice(): JSX.Element {

    const navigate = useNavigate();
    
    const authToken: string|null = useSelector<RootStateType, string|null>(state => {
        let _authToken = state.auth.authToken;

        if(_authToken === null || _authToken === undefined){
            const sessAuthToken = sessionStorage.getItem("authToken");
            if(sessAuthToken){
                _authToken = sessAuthToken;
            }
        }

        return _authToken;
    });
    const privilege: number|null = useSelector<RootStateType, number|null>(state => state.auth.privilege);

    const { TextArea } = Input;

    const [noticeNo, setNoticeNo] = useState<string>('');
    const [display, setDisplay] = useState<string>('1');
    const [title, setTitle] = useState<string>('');
    const [content, setContent] = useState<string>('');
    const [noticeMsg, setNoticeMsg] = useState<string>('');

    function onChangeNoticeNoHandler(event: React.ChangeEvent<HTMLInputElement>){
        event.preventDefault();

        const newNoticeNo = event.currentTarget.value;

        setNoticeNo(newNoticeNo);
    }

    function onChangeDisplayNoticeHandler(value: string){
        const newDisplay = value;

        // console.log('manage_notice.tsx.onChangeDisplayNoticeHandler.newDisplay:', value);

        setDisplay(newDisplay);
    }

    function onChangeTitleHandler(event: React.ChangeEvent<HTMLInputElement>){
        event.preventDefault();

        const newTitle = event.currentTarget.value;

        setTitle(newTitle);
    }

    function onChangeContentHandler(event: React.ChangeEvent<HTMLTextAreaElement>){
        event.preventDefault();

        const newContent = event.currentTarget.value;

        setContent(newContent);
    }

    function checkNoticeTitleAndContents(type: string='create'){

        let isValid = false;

        if(type === 'create'){
            isValid = (title.length >= 5) && (content.length >= 5) ? true : false;
        }
        else{
            isValid = (title.length >= 5 || title.length == 0) && (content.length >= 5 || title.length == 0) ? true : false;
        }

        if(isValid === false) {
            setNoticeMsg('공지의 제목과 내용을 확인해 주세요(각각 길이 5글자 이상).');
        }
        else {
            setNoticeMsg('');
        }

        return isValid;
    }

    function checkNoticeNo(){
        const isValid = parseInt(noticeNo) > 0;

        if(isValid === false){
            setNoticeMsg('공지글 번호를 확인해주세요.');
        }
        else{
            setNoticeMsg('');
        }

        return isValid;
    }

    // function onClickUpdateBtnHandler(event: React.MouseEvent<HTMLButtonElement>){
    function onClickUpdateBtnHandler(event: React.MouseEvent){
        event.preventDefault();

        let isValid = checkNoticeTitleAndContents('update');
        isValid = isValid && checkNoticeNo();

        if(isValid){
            if(authToken){
                let headerData = new Headers();
                let formData = new FormData();

                headerData.set("authData", authToken);

                formData.set("noticeNo", noticeNo);
                formData.set("display", display);
                formData.set("title", title);
                formData.set("content", content);

                fetch('/api/agora/updatenotice', {
                    method: 'POST'
                    , headers: headerData
                    , body: formData
                }).then((response) => {
                    return response.json();
                }).then((resBody) => {
                    if(resBody["success"] === true){
                        setNoticeNo('');
                        setDisplay('1');
                        setTitle('');
                        setContent('');
                        setNoticeMsg('');
                    }
                    else{
                        setNoticeMsg('오류가 발생했습니다. 입력 내용들을 확인해주세요.');
                    }
                }).catch((error) => {
                    console.log('[Error]manage_notice.tsx.onClickUpdateBtnHandler:', error);
                });
            }
        }
    }

    // function onClickCreateBtnHandler(event: React.MouseEvent<HTMLButtonElement>){
    function onClickCreateBtnHandler(event: React.MouseEvent){
        event.preventDefault();

        const isValid = checkNoticeTitleAndContents('create');

        if(isValid){
            if(authToken){
                let headerData = new Headers();
                let formData = new FormData();

                headerData.set("authData", authToken);

                formData.set("display", display);
                formData.set("title", title);
                formData.set("content", content);

                fetch('/api/agora/createnotice', {
                    method: 'POST'
                    , headers: headerData
                    , body: formData
                }).then((response) => {
                    return response.json();
                }).then((resBody) => {
                    if(resBody["success"] === true){
                        setNoticeNo('');
                        setDisplay('1');
                        setTitle('');
                        setContent('');
                        setNoticeMsg('');
                    }
                    else{
                        setNoticeMsg('오류가 발생했습니다. 입력 내용들을 확인해주세요.');
                    }
                }).catch((error) => {
                    console.log('[Error]manage_notice.tsx.onClickUpdateBtnHandler:', error);
                })
            }
        }
    }

    // function onClickDeleteBtnHandler(event: React.MouseEvent<HTMLButtonElement>){
    function onClickDeleteBtnHandler(event: React.MouseEvent){
        event.preventDefault();

        if(authToken){
            let headerData = new Headers();
            let formData = new FormData();

            headerData.set("authData", authToken);

            formData.set("noticeNo", noticeNo);

            fetch('/api/agora/deletenotice', {
                method: 'POST'
                , headers: headerData
                , body: formData
            }).then((response) => {
                return response.json();
            }).then((resBody) => {
                if(resBody["success"] === true){
                    setNoticeNo('');
                    setDisplay('1');
                    setTitle('');
                    setContent('');
                    setNoticeMsg('');
                }
                else{
                    setNoticeMsg('오류가 발생했습니다. 입력 내용들을 확인해주세요.');
                }
            }).catch((error) => {
                console.log('[Error]manage_notice.tsx.onClickUpdateBtnHandler:', error);
            })
        }
    }

    type getnoticeResBodyType = {
        success: boolean
        , title: string
        , content: string
        , timeCreated: string
        , display: number
    };

    // function onClickReadNoticeHandler(event: React.MouseEvent<HTMLButtonElement>){
    function onClickReadNoticeHandler(event: React.MouseEvent){
        event.preventDefault();

        if(authToken){
            let headerData = new Headers();
            let formData = new FormData();

            headerData.set("authData", authToken);

            formData.set("noticeNo", noticeNo);

            fetch('/api/agora/getnotice', {
                method: 'POST'
                , headers: headerData
                , body: formData
            }).then((response) => {
                return response.json();
            }).then((resBody: getnoticeResBodyType) => {
                if(resBody["success"] === true){
                    setDisplay(resBody["display"].toString());
                    setTitle(resBody["title"]);
                    setContent(resBody["content"]);
                    setNoticeMsg('');
                }
                else{
                    setNoticeNo('');
                    setDisplay('1');
                    setTitle('');
                    setContent('');
                    setNoticeMsg('');
                }
            }).catch((error) => {
                console.log('[Error]manage_notice.tsx.onClickReadNoticeHandler:', error);
            });
        }
    }

    useEffect(() => {
        // console.log('manage_notice.tsx.useEffect.privilege:', privilege);
        if(typeof(privilege) == 'number' && privilege < 1){
            navigate('/home');
        }
    }, [privilege]);

    return(
        <AppLayout>
        {(typeof(privilege) == 'number' && privilege > 0) &&
        <>
            <Row id="manage_notice_notice_no_div">
                <Col
                    xs={{span: 24}} sm={{span: 24}} md={{span: 24}}
                    lg={{span: 24}} xl={{span: 24}} xxl={{span: 24}}               
                >
                    <Input onChange={onChangeNoticeNoHandler} value={noticeNo} addonBefore="Notice No."></Input>
                </Col>
            </Row>
            <Row id="manage_notice_display_div">
                <Col
                    xs={{span: 24}} sm={{span: 24}} md={{span: 24}}
                    lg={{span: 24}} xl={{span: 24}} xxl={{span: 24}}               
                >
                    <Select defaultValue='1' onChange={onChangeDisplayNoticeHandler} value={display} options={[{value: '1', label: 'Display'}, {value: '0', label: 'Hide'}]}/>
                </Col>
            </Row>
            <Row id="manage_notice_title_div">
                <Col
                    xs={{span: 24}} sm={{span: 24}} md={{span: 24}}
                    lg={{span: 24}} xl={{span: 24}} xxl={{span: 24}}               
                >
                    <Input onChange={onChangeTitleHandler} value={title} addonBefore="Title"></Input>
                </Col>
            </Row>
            <Row id="manage_notice_content_div">
                <Col
                    xs={{span: 24}} sm={{span: 24}} md={{span: 24}}
                    lg={{span: 24}} xl={{span: 24}} xxl={{span: 24}}               
                >
                    <TextArea onChange={onChangeContentHandler} placeholder="100글자까지 입력 가능합니다" maxLength={100} showCount={true} allowClear={true} value={content}></TextArea>
                </Col>
            </Row>
            <Row id="manage_notice_msg_div">
                <Col
                    xs={{span: 24}} sm={{span: 24}} md={{span: 24}}
                    lg={{span: 24}} xl={{span: 24}} xxl={{span: 24}}               
                >
                    {noticeMsg}
                </Col>
            </Row>
            <Row id="manage_notice_button_div">
                <Col
                    xs={{span: 24}} sm={{span: 24}} md={{span: 24}}
                    lg={{span: 24}} xl={{span: 24}} xxl={{span: 24}}               
                >
                    <Button onClick={onClickReadNoticeHandler}>불러오기</Button>
                    <Button onClick={onClickUpdateBtnHandler}>수정</Button>
                    <Button onClick={onClickCreateBtnHandler}>입력</Button>
                    <Button onClick={onClickDeleteBtnHandler}>삭제</Button>
                </Col>
            </Row>
        </>
        }
        </AppLayout>
    )

}

export default ManageNotice;
