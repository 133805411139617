import React from 'react';
import logo from './logo.svg';
import 'antd/dist/reset.css';
import './App.css';

import { BrowserRouter, Routes, Route } from 'react-router-dom';

import NavBar from './components/navbar';
import WithCheckAuth from './components/check_auth';
import AppFooter from './components/app_footer';

import Home from './views/home';
import Signup from './views/signup';
import Signin from './views/signin';
// import ReqPWReset from './views/reqpwreset';
import MyInfo from './views/myinfo';
import ManageNotice from './views/manage_notice';


import { Layout } from 'antd';

const { Header, Content, Footer } = Layout;

// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.tsx</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }

function App() {
  return(
    <div className="App">
      <BrowserRouter>
        <Layout>
          <Header>
            <NavBar></NavBar>
          </Header>
          <Content id="content_wrapper">
            <Routes>
              <Route path="/" element={
                <WithCheckAuth
                  InputComponent={Home}
                  loginRequired={false}
                  props={{message: 'test message'}}>
                </WithCheckAuth>
              }>
              </Route>
              <Route path="/home" element={
                <WithCheckAuth
                  InputComponent={Home}
                  loginRequired={false}
                  props={{message: 'test message'}}>
                </WithCheckAuth>
              }>
              </Route>
              <Route path="/user/signup" element={<Signup/>}></Route>
              <Route path="/user/signin" element={<Signin/>}></Route>
              {/* <Route path="/user/reqpwreset" element={<ReqPWReset/>}></Route> */}
              <Route path="/user/myinfo" element={
                  <WithCheckAuth
                      InputComponent={MyInfo}
                      loginRequired={true}>
                  </WithCheckAuth>}>
              </Route> 
              <Route path="/managenotice" element={
                <WithCheckAuth
                  InputComponent={ManageNotice}
                  loginRequired={true}
                ></WithCheckAuth>
              }>
              </Route>
            </Routes>
          </Content>
          <Footer>
            <AppFooter></AppFooter>
          </Footer>
        </Layout>
      </BrowserRouter>
    </div>
  );
}

export default App;
