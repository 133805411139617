import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

// import { SigninData, getAuth, signinUser } from "../reducers/user_auth";
import { SigninData, signinUser, getAuth } from "../features/user_auth";

import { AppDispatchType, RootStateType } from "..";
import AppLayout from "../components/app_layout";

import { Input, Button, Divider } from "antd";

import "../css/signin.css";


function Signin(): JSX.Element {

    const dispatch = useDispatch<AppDispatchType>();
    const navigate = useNavigate();

    let [email, setEmail] = useState<string>('');
    let [emailMessage, setEmailMessage] = useState('');
    let [passwd, setPassword] = useState<string>('');
    let [passwdMessage, setPasswdMessage] = useState('');

    const isLoggedIn: boolean = useSelector<RootStateType, boolean>(state => state.auth.isLoggedIn);

    const onChangeEmailHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        setEmail(event.currentTarget.value);
        setEmailMessage('');

        // console.log('signin.tsx.onChangeEmailHandler().value', event.currentTarget.value);

        // const datalist = document.getElementById("user_email_list");
        // if(datalist){
        //     datalist.innerHTML = "";
        // }
    }

    const onChangePasswdHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        setPassword(event.currentTarget.value);
        setPasswdMessage('');
    }

    const checkSubmitValidity = () => {
        let pat0 = /^[A-Za-z]{1}[A-Za-z0-9\.\-_]+@[A-Za-z0-9\-_]+\.[a-z]{1,8}\.?[a-z]{1,6}$/;
        let isEmailValid = pat0.test(email);
        // let isPasswdValid = (passwd.length >= 8) ? true : false;

        if(!isEmailValid){
            setEmailMessage('이메일 주소를 확인해주세요.');
        }

        // return isEmailValid && isPasswdValid;
        return isEmailValid;
    };

    type getAuthResBodyType = {
        authenticated: boolean
        , userNo: number
        , email: string
        , username: string
        , privilege: number
        , authToken: string
    };

    const checkAndSendSigninData = () => {
        const isInputValid = checkSubmitValidity();

        if(isInputValid){

            let data: SigninData = {
                email: email,
                password: passwd
            }

            //dispatch(getAuth(data)).then((resBody: any) => {
            //    let authenticated: boolean = resBody.authenticated;

            //    // console.log('signin.onSubmitHandler.resBody:', resBody);
            //    console.log('signin.onSubmitHandler.authenticated:', authenticated);

            //    if(authenticated == true){
            //        let data :any = {
            //            authToken: resBody.authToken,
            //            userNo: resBody.userNo,
            //            userEmail: resBody.email,
            //            username: resBody.username,
            //            privilege: resBody.privilege
            //        };
            //        
            //        setEmailMessage('');
            //        setPasswdMessage('');
            //        dispatch(signinUser(data));
            //    }
            //    else{
            //        setEmailMessage('이메일 주소를 확인해주세요.');
            //        setPasswdMessage('패스워드를 확인해주세요.');
            //    }
            //}).catch((error) => {
            //    console.log('[Error]Signin.onSubmitHandler:', error);
            //});

            getAuth(data).then((resBody: getAuthResBodyType) => {
                const authenticated: boolean = resBody.authenticated;

                console.log('signin.onSubmitHandler.authenticated:', authenticated);
                // console.log('signin.onSubmitHandler.resBody:', resBody);

                if(authenticated == true){
                    const authData :any = {
                        authToken: resBody.authToken,
                        userNo: resBody.userNo,
                        userEmail: resBody.email,
                        username: resBody.username,
                        privilege: resBody.privilege
                    };

                    // console.log('signin.tsx.checkAndSendSigninData.getAuth.data.privilege:%d(%s)', authData["privilege"], typeof(authData["privilege"]));
                    
                    // console.log('signin.tsx.checkAndSendSigninData.getAuth.data.userNo:%d(%s)', authData["userNo"], typeof(authData["userNo"]));

                    setEmailMessage('');
                    setPasswdMessage('');
                    dispatch(signinUser(authData));
                }
                else{
                    setEmailMessage('이메일 주소를 확인해주세요.');
                    setPasswdMessage('패스워드를 확인해주세요.');
                }
            }).catch((error) => {
                console.log('[Error]Signin.checkAndSendSigninData:', error);
            });
        }
    };

    // const onSubmitHandler = (event: React.FormEvent<HTMLFormElement>) => {
    // const onSubmitHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    const onSubmitHandler = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();

        // let data: SigninData = {
        //     email: email,
        //     password: passwd
        // };

        // dispatch(signinUser(data));

        checkAndSendSigninData();
    };

    // const onCancelHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    const onCancelHandler = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        navigate('/home');
    };

    // function onHandleUserEmailListSelect() {
    //     console.log('signin.tsx.onHandleUserEmailListSelect()');
    // }

    function onHandleInputKeyUp(event: React.KeyboardEvent<HTMLInputElement>) {
        // if(event.key === 'Enter'){
        //     event.preventDefault();
        //     checkAndSendSigninData();
        // }

        event.preventDefault();

        if(event.key === 'Enter'){
            checkAndSendSigninData();
        }
        else{
            const value = event.currentTarget.value;

            const domainPat = /([a-z0-9]+)(@[a-z\.]*)/;

            const matches = value.match(domainPat);

            if(matches && matches.length > 0){

                const domains = userDomains.filter((userDomain) => {
                    if(userDomain !== matches[2]){
                        return userDomain.startsWith(matches[2]);
                    }
                });

                // const userEmails = domains.map((domain) => {
                //     return matches[1] + domain;
                // });

                const userEmails: Array<string> = [];

                if(domains && domains.length > 0){

                    const numOfItems = domains.length > 3 ? 3 : domains.length;

                    for(let idx = 0; idx < numOfItems; idx = idx + 1){
                        userEmails.push(matches[1] + domains[idx]);
                    }
                }

                // console.log('userEmails:', userEmails);

                if(userEmails.length > 0){
                    const datalist = document.getElementById("user_email_list");
                    if(datalist){
                        datalist.innerHTML = "";

                        userEmails.forEach((userEmail) => {
                            const option = document.createElement("option");
                            option.value = userEmail;
                            datalist.appendChild(option);
                        });

                        // console.log('signin.tsx.onHandleKeyUp().datalist:', datalist);
                    }
                }
            }
        }   //  if(event.key === 'Enter')
    }   //  function onHandleInputKeyUp(event: React.KeyboardEvent<HTMLInputElement>)

    const onForgotPassword = (event: React.MouseEvent<HTMLAnchorElement>) => {
        event.preventDefault();
        navigate('/user/reqpwreset');
    };

    const userDomains = ["@naver.com", "@gmail.com", "@daum.net", "@snu.ac.kr", "@ewha.ac.kr"]

    useEffect(() => {
        if(isLoggedIn){
            navigate('/home');
            // navigate('/friends/list');
            // navigate('/topics')
        }
    }, [isLoggedIn]);

    return(
        <AppLayout>
            <div id="signin_top_container_div">
                {!isLoggedIn &&
                    <div>
                        <div id="signin_page_title_div">Sign In</div>
                        {/* <form onSubmit={onSubmitHandler}> */}
                        <form>
                            <div id="signin_email_container_div" className="signin_input_container_div">
                                <Input id="signin_email_input" value={email} onChange={onChangeEmailHandler} addonBefore="Email*" list="user_email_list" onKeyUp={onHandleInputKeyUp}></Input>
                                <datalist id="user_email_list"></datalist>
                                <div id="signin_email_msg_div" className="signin_msg_div">
                                    {emailMessage}
                                </div>
                            </div>
                            <div id="signin_passwd_container_div" className="signin_input_container_div">
                                <Input.Password className="password" value={passwd} onChange={onChangePasswdHandler} addonBefore="Password*" onKeyUp={onHandleInputKeyUp}></Input.Password>
                                <div id="signin_passwd_msg_div" className="signin_msg_div">
                                    {passwdMessage}
                                </div>
                            </div>
                            <Divider/>
                            <div id="signin_button_container_div">
                                {/* <Button htmlType="submit" type="primary">제출</Button> */}
                                <Button type="primary" id="submit_button" onClick={onSubmitHandler}>제출</Button>
                                <Button onClick={onCancelHandler}>홈으로</Button>
                            </div>
                        </form>
                        <div id="signin_resetpw_div">
                            {/* <a href="" onClick={onForgotPassword}>비밀번호 찾기</a> */}
                            비밀번호를 잊으신 경우 askteachme@gmail.com으로 연락주세요.
                        </div>
                    </div>
                }
            </div>
        </AppLayout>
    );
}

export default Signin;
