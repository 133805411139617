import React from "react";

import '../css/app_footer.css';

// import FooterInfo from '../resources/image/contact_info_footer.svg';
// import FooterInfo from '../resources/image/contact_info_footer_2023_outline.svg';

function AppFooter() {
    return (
        <div className="app_footer_div">
            {/* <img src={FooterInfo} alt="Info"></img> */}
            {/* <image href={FooterInfo}/> */}
            <span id="footer_svg_span">
               <svg
                  id="svg8"
                  version="1.1"
                  viewBox="0 0 190 10"
                  xmlns="http://www.w3.org/2000/svg">
                 <g
                    id="layer1"
                    transform="translate(14.026848,-1.2116314)">
                   <g
                      aria-label="© 미니티 minitee 2023, askteachme@gmail.com"
                      id="text835"
                      >
                     <path
                        d="m -9.00642,8.738932 c 1.642534,0 3.0564679,-1.2276672 3.0564679,-3.1580679 0,-1.9219341 -1.4139339,-3.1242013 -3.0564679,-3.1242013 -1.642534,0 -3.056468,1.2022672 -3.056468,3.1242013 0,1.9304007 1.413934,3.1580679 3.056468,3.1580679 z m 0,-0.3894668 c -1.430867,0 -2.616201,-1.1176005 -2.616201,-2.7686011 0,-1.6510007 1.185334,-2.7347344 2.616201,-2.7347344 1.4224006,0 2.616201,1.0837337 2.616201,2.7347344 0,1.6510006 -1.1938004,2.7686011 -2.616201,2.7686011 z m 0.067733,-0.897467 c 0.5672669,0 0.9398003,-0.2286001 1.2784671,-0.5334003 L -7.9396196,6.5121978 c -0.2794001,0.2370667 -0.5588002,0.4233335 -0.9652004,0.4233335 -0.6858002,0 -1.126067,-0.5249336 -1.126067,-1.3546672 0,-0.745067 0.4572001,-1.2700005 1.1514671,-1.2700005 0.3386668,0 0.5672668,0.1439334 0.8297336,0.3894668 L -7.7279528,4.3277969 C -8.0242863,4.0229968 -8.3714197,3.7943967 -8.90482,3.7943967 c -0.9144003,0 -1.744134,0.6858003 -1.744134,1.7864674 0,1.1684004 0.7620003,1.8711341 1.7102674,1.8711341 z"
                        />
                     <path
                        d="M -2.224605,2.4143295 V 7.3503981 H 1.2636631 V 2.4143295 Z M 0.58632949,2.9731297 V 6.8000646 H -1.5472714 V 2.9731297 Z M 2.8553971,1.6692625 V 9.2807989 H 3.549664 V 1.6692625 Z"
                        />
                     <path
                        d="M 6.2928658,2.4058628 H 5.5985989 v 4.8937353 h 0.6265336 c 1.2107338,0 2.4045343,-0.084667 3.7253348,-0.3556001 l -0.0762,-0.5926669 C 8.6127334,6.6053312 7.4358663,6.7069312 6.2928658,6.7069312 Z M 10.653201,1.6692625 v 7.5861364 h 0.694267 V 1.6692625 Z"
                        />
                     <path
                        d="m 14.082203,5.0474639 h 2.717801 V 4.4886636 H 14.082203 V 2.9223297 h 2.997201 V 2.3550628 h -3.683001 v 5.080002 h 0.601133 c 1.447801,0 2.463801,-0.0508 3.649135,-0.2540001 L 17.578938,6.6137978 C 16.444404,6.8169979 15.46227,6.8677979 14.082203,6.8677979 Z m 4.368802,-3.3782014 v 7.5861364 h 0.694267 V 1.6692625 Z"
                        />
                     <path
                        d="m 23.404012,8.637332 h 0.770467 V 5.301464 c 0.423334,-0.4741336 0.8128,-0.7027336 1.159934,-0.7027336 0.5842,0 0.855133,0.3640668 0.855133,1.2276671 V 8.637332 h 0.770467 V 5.301464 c 0.431801,-0.4741336 0.804334,-0.7027336 1.159934,-0.7027336 0.5842,0 0.855134,0.3640668 0.855134,1.2276671 V 8.637332 h 0.770467 V 5.7247975 c 0,-1.1768672 -0.448734,-1.8034007 -1.397001,-1.8034007 -0.5588,0 -1.0414,0.3640668 -1.515534,0.8805336 -0.186266,-0.5418668 -0.567266,-0.8805336 -1.27,-0.8805336 -0.550334,0 -1.032934,0.3471334 -1.430867,0.7874003 h -0.0254 l -0.06773,-0.668867 h -0.635 z"
                        />
                     <path
                        d="M 31.244153,8.637332 H 32.01462 V 4.0399301 H 31.244153 Z M 31.63362,3.0916631 c 0.3048,0 0.5334,-0.2116668 0.5334,-0.4995335 0,-0.3132668 -0.2286,-0.4995336 -0.5334,-0.4995336 -0.3048,0 -0.5334,0.1862668 -0.5334,0.4995336 0,0.2878667 0.2286,0.4995335 0.5334,0.4995335 z"
                        />
                     <path
                        d="m 33.572484,8.637332 h 0.770467 V 5.301464 c 0.465667,-0.4656669 0.787401,-0.7027336 1.261534,-0.7027336 0.6096,0 0.872067,0.3640668 0.872067,1.2276671 V 8.637332 h 0.770467 V 5.7247975 c 0,-1.1768672 -0.440267,-1.8034007 -1.405467,-1.8034007 -0.626534,0 -1.100667,0.3471334 -1.540934,0.7789336 h -0.0254 l -0.06773,-0.6604003 h -0.635001 z"
                        />
                     <path
                        d="m 38.745622,8.637332 h 0.770467 V 4.0399301 h -0.770467 z m 0.389467,-5.5456689 c 0.3048,0 0.5334,-0.2116668 0.5334,-0.4995335 0,-0.3132668 -0.2286,-0.4995336 -0.5334,-0.4995336 -0.3048,0 -0.5334,0.1862668 -0.5334,0.4995336 0,0.2878667 0.2286,0.4995335 0.5334,0.4995335 z"
                        />
                     <path
                        d="m 42.521754,8.7473987 c 0.2794,0 0.5842,-0.084667 0.846667,-0.1693334 L 43.21602,8.0023317 c -0.1524,0.059267 -0.364066,0.1185334 -0.524933,0.1185334 -0.5334,0 -0.7112,-0.3217335 -0.7112,-0.8890004 v -2.565401 h 1.2446 V 4.0399301 h -1.2446 V 2.7529963 H 41.33642 l -0.09313,1.2869338 -0.719667,0.042333 v 0.5842002 h 0.685801 v 2.5484677 c 0,0.922867 0.3302,1.5324673 1.312334,1.5324673 z"
                        />
                     <path
                        d="m 45.993099,8.7473987 c 0.626534,0 1.1176,-0.2116668 1.524001,-0.4741335 L 47.237699,7.7652649 c -0.338666,0.2286001 -0.702733,0.3640669 -1.151467,0.3640669 -0.872067,0 -1.4732,-0.6265336 -1.524,-1.6086673 h 3.098801 c 0.01693,-0.1100668 0.03387,-0.2709335 0.03387,-0.4402669 0,-1.3123338 -0.660401,-2.1590008 -1.828801,-2.1590008 -1.066801,0 -2.074334,0.9313337 -2.074334,2.4214676 0,1.5155339 0.982133,2.4045343 2.201334,2.4045343 z M 44.553765,5.9703309 c 0.09313,-0.9059337 0.668867,-1.4308672 1.320801,-1.4308672 0.719667,0 1.143,0.4995335 1.143,1.4308672 z"
                        />
                     <path
                        d="m 50.683643,8.7473987 c 0.626534,0 1.117601,-0.2116668 1.524001,-0.4741335 l -0.2794,-0.5080003 c -0.338667,0.2286001 -0.702734,0.3640669 -1.151468,0.3640669 -0.872067,0 -1.4732,-0.6265336 -1.524,-1.6086673 h 3.098801 c 0.01693,-0.1100668 0.03387,-0.2709335 0.03387,-0.4402669 0,-1.3123338 -0.660401,-2.1590008 -1.828801,-2.1590008 -1.0668,0 -2.074334,0.9313337 -2.074334,2.4214676 0,1.5155339 0.982134,2.4045343 2.201334,2.4045343 z M 49.244309,5.9703309 c 0.09313,-0.9059337 0.668867,-1.4308672 1.320801,-1.4308672 0.719667,0 1.143,0.4995335 1.143,1.4308672 z"
                        />
                     <path
                        d="m 55.010124,8.637332 h 3.903135 V 7.968465 h -1.718734 c -0.313267,0 -0.694267,0.033867 -1.016,0.059267 1.456267,-1.3800672 2.429934,-2.641601 2.429934,-3.8777349 0,-1.1091337 -0.694267,-1.8372674 -1.803401,-1.8372674 -0.7874,0 -1.3208,0.3640669 -1.828801,0.9144004 l 0.448734,0.4402669 c 0.338667,-0.4064002 0.778934,-0.719667 1.286934,-0.719667 0.770467,0 1.143,0.5164669 1.143,1.2276672 0,1.0668004 -0.897467,2.3029342 -2.844801,4.0047349 z"
                        />
                     <path
                        d="m 61.69033,8.7473987 c 1.176867,0 1.930401,-1.0752671 1.930401,-3.234268 0,-2.1505342 -0.753534,-3.2004013 -1.930401,-3.2004013 -1.185334,0 -1.938867,1.0414005 -1.938867,3.2004013 0,2.1590009 0.753533,3.234268 1.938867,3.234268 z m 0,-0.6180669 c -0.7112,0 -1.185334,-0.7874004 -1.185334,-2.6162011 0,-1.8118674 0.474134,-2.5738677 1.185334,-2.5738677 0.702734,0 1.176867,0.7620003 1.176867,2.5738677 0,1.8288007 -0.474133,2.6162011 -1.176867,2.6162011 z"
                        />
                     <path
                        d="m 64.408134,8.637332 h 3.903135 V 7.968465 h -1.718734 c -0.313267,0 -0.694267,0.033867 -1.016,0.059267 1.456267,-1.3800672 2.429934,-2.641601 2.429934,-3.8777349 0,-1.1091337 -0.694267,-1.8372674 -1.803401,-1.8372674 -0.7874,0 -1.3208,0.3640669 -1.8288,0.9144004 l 0.448733,0.4402669 c 0.338667,-0.4064002 0.778934,-0.719667 1.286934,-0.719667 0.770467,0 1.143,0.5164669 1.143,1.2276672 0,1.0668004 -0.897467,2.3029342 -2.844801,4.0047349 z"
                        />
                     <path
                        d="m 70.969807,8.7473987 c 1.100667,0 1.989667,-0.6604003 1.989667,-1.7695341 0,-0.846667 -0.5842,-1.4054672 -1.3208,-1.5748006 v -0.033867 c 0.6604,-0.2455334 1.109134,-0.7450669 1.109134,-1.4986006 0,-0.999067 -0.762001,-1.5578673 -1.803401,-1.5578673 -0.719667,0 -1.270001,0.3132668 -1.735668,0.745067 l 0.414867,0.4910669 c 0.347134,-0.3471335 0.787401,-0.6011336 1.286934,-0.6180669 0.643467,0.016933 1.049867,0.3979334 1.049867,0.999067 0,0.668867 -0.440267,1.1853338 -1.718734,1.1853338 v 0.5926669 c 1.430867,0 1.930401,0.4995336 1.930401,1.2446005 0,0.7112003 -0.524934,1.1514672 -1.261534,1.1514672 -0.702733,0 -1.176867,-0.3302002 -1.540934,-0.7112003 l -0.389467,0.5080002 c 0.406401,0.4402668 1.016001,0.846667 1.989668,0.846667 z"
                        />
                     <path
                        d="m 74.068611,10.245999 c 0.762001,-0.3217332 1.236134,-0.9567334 1.236134,-1.7695338 0,-0.5672669 -0.245533,-0.9144003 -0.651933,-0.9144003 -0.313267,0 -0.584201,0.2116667 -0.584201,0.5418669 0,0.3556001 0.270934,0.5418668 0.567267,0.5418668 0.03387,0 0.05927,0 0.09313,-0.00847 -0.0085,0.5164668 -0.321734,0.922867 -0.846667,1.1514671 z"
                        />
                     <path
                        d="m 79.521153,8.7473987 c 0.567267,0 1.083734,-0.2963335 1.524001,-0.6604003 h 0.0254 l 0.06773,0.5503336 h 0.635 V 5.8094642 c 0,-1.1345338 -0.474133,-1.8880674 -1.591734,-1.8880674 -0.7366,0 -1.388533,0.3302001 -1.811867,0.6011335 l 0.3048,0.5334002 c 0.364067,-0.2455334 0.846667,-0.4910668 1.380067,-0.4910668 0.753534,0 0.948267,0.5672669 0.948267,1.1599338 -1.9558,0.2201334 -2.827867,0.7196669 -2.827867,1.718734 0,0.8297337 0.575733,1.3038672 1.3462,1.3038672 z m 0.220134,-0.6180669 c -0.457201,0 -0.812801,-0.2116668 -0.812801,-0.7366003 0,-0.5926669 0.524934,-0.9736671 2.074334,-1.1599338 v 1.2869338 c -0.448733,0.3979335 -0.8128,0.6096003 -1.261533,0.6096003 z"
                        />
                     <path
                        d="m 84.440279,8.7473987 c 1.083734,0 1.6764,-0.6180669 1.6764,-1.3631339 0,-0.872067 -0.7366,-1.1430005 -1.405467,-1.3970006 -0.516467,-0.1947334 -1.016,-0.3556001 -1.016,-0.7958669 0,-0.3556002 0.262467,-0.6604003 0.8382,-0.6604003 0.414867,0 0.7366,0.1693334 1.058334,0.4064002 l 0.372533,-0.4995336 c -0.364066,-0.2794001 -0.8636,-0.5164668 -1.430867,-0.5164668 -0.999067,0 -1.583267,0.5757335 -1.583267,1.3038671 0,0.7789337 0.728134,1.0922005 1.3716,1.3292672 0.508001,0.1947334 1.049868,0.4064002 1.049868,0.872067 0,0.3979335 -0.296334,0.719667 -0.905934,0.719667 -0.550334,0 -0.965201,-0.2201334 -1.363134,-0.5418669 l -0.381,0.5080002 c 0.440267,0.3640668 1.0668,0.6350003 1.718734,0.6350003 z"
                        />
                     <path
                        d="m 87.200422,8.637332 h 0.762 V 7.4265981 l 0.8636,-1.0075337 1.346201,2.2182676 h 0.8382 L 89.266289,5.8856642 90.807223,4.0399301 h -0.855134 l -1.972734,2.4299343 h -0.01693 V 1.8978626 h -0.762 z"
                        />
                     <path
                        d="m 93.127081,8.7473987 c 0.2794,0 0.5842,-0.084667 0.846667,-0.1693334 l -0.1524,-0.5757336 c -0.1524,0.059267 -0.364067,0.1185334 -0.524933,0.1185334 -0.533401,0 -0.711201,-0.3217335 -0.711201,-0.8890004 v -2.565401 h 1.244601 V 4.0399301 H 92.585214 V 2.7529963 h -0.643467 l -0.09313,1.2869338 -0.719667,0.042333 v 0.5842002 h 0.6858 v 2.5484677 c 0,0.922867 0.3302,1.5324673 1.312334,1.5324673 z"
                        />
                     <path
                        d="m 96.59843,8.7473987 c 0.626534,0 1.117601,-0.2116668 1.524001,-0.4741335 l -0.2794,-0.5080003 c -0.338667,0.2286001 -0.702734,0.3640669 -1.151467,0.3640669 -0.872067,0 -1.473201,-0.6265336 -1.524001,-1.6086673 h 3.098801 c 0.01693,-0.1100668 0.03387,-0.2709335 0.03387,-0.4402669 0,-1.3123338 -0.6604,-2.1590008 -1.828801,-2.1590008 -1.0668,0 -2.074334,0.9313337 -2.074334,2.4214676 0,1.5155339 0.982134,2.4045343 2.201334,2.4045343 z M 95.159096,5.9703309 c 0.09313,-0.9059337 0.668867,-1.4308672 1.320801,-1.4308672 0.719667,0 1.143,0.4995335 1.143,1.4308672 z"
                        />
                     <path
                        d="m 100.35763,8.7473987 c 0.56727,0 1.08374,-0.2963335 1.524,-0.6604003 h 0.0254 l 0.0677,0.5503336 h 0.635 V 5.8094642 c 0,-1.1345338 -0.47414,-1.8880674 -1.59174,-1.8880674 -0.7366,0 -1.388531,0.3302001 -1.811865,0.6011335 l 0.3048,0.5334002 c 0.364067,-0.2455334 0.846665,-0.4910668 1.380065,-0.4910668 0.75354,0 0.94827,0.5672669 0.94827,1.1599338 -1.955801,0.2201334 -2.827868,0.7196669 -2.827868,1.718734 0,0.8297337 0.575733,1.3038672 1.346198,1.3038672 z m 0.22014,-0.6180669 c -0.4572,0 -0.812805,-0.2116668 -0.812805,-0.7366003 0,-0.5926669 0.524935,-0.9736671 2.074335,-1.1599338 v 1.2869338 c -0.44873,0.3979335 -0.8128,0.6096003 -1.26153,0.6096003 z"
                        />
                     <path
                        d="m 105.88636,8.7473987 c 0.55033,0 1.07527,-0.2116668 1.48167,-0.5842003 l -0.3302,-0.5164668 c -0.28787,0.2624667 -0.6604,0.4572002 -1.08374,0.4572002 -0.84667,0 -1.4224,-0.7027337 -1.4224,-1.7610674 0,-1.0583338 0.6096,-1.7780007 1.43933,-1.7780007 0.37254,0 0.65194,0.1693334 0.92287,0.4148668 l 0.39794,-0.5164669 c -0.3302,-0.2878667 -0.74507,-0.5418668 -1.34621,-0.5418668 -1.17686,0 -2.21826,0.8890003 -2.21826,2.4214676 0,1.5240006 0.9398,2.4045343 2.159,2.4045343 z"
                        />
                     <path
                        d="m 108.38404,8.637332 h 0.77047 V 5.301464 c 0.46566,-0.4656669 0.7874,-0.7027336 1.26153,-0.7027336 0.6096,0 0.87207,0.3640668 0.87207,1.2276671 V 8.637332 h 0.77046 V 5.7247975 c 0,-1.1768672 -0.44026,-1.8034007 -1.40546,-1.8034007 -0.62654,0 -1.10067,0.3471334 -1.524,0.7704669 l 0.0254,-0.948267 V 1.8978626 h -0.77047 z"
                        />
                     <path
                        d="m 113.52331,8.637332 h 0.77046 V 5.301464 c 0.42334,-0.4741336 0.8128,-0.7027336 1.15994,-0.7027336 0.5842,0 0.85513,0.3640668 0.85513,1.2276671 V 8.637332 h 0.77047 V 5.301464 c 0.4318,-0.4741336 0.80433,-0.7027336 1.15993,-0.7027336 0.5842,0 0.85513,0.3640668 0.85513,1.2276671 V 8.637332 h 0.77047 V 5.7247975 c 0,-1.1768672 -0.44873,-1.8034007 -1.397,-1.8034007 -0.5588,0 -1.0414,0.3640668 -1.51553,0.8805336 -0.18627,-0.5418668 -0.56727,-0.8805336 -1.27,-0.8805336 -0.55034,0 -1.03294,0.3471334 -1.43087,0.7874003 h -0.0254 l -0.0677,-0.668867 h -0.635 z"
                        />
                     <path
                        d="m 123.21765,8.7473987 c 0.62653,0 1.1176,-0.2116668 1.524,-0.4741335 l -0.2794,-0.5080003 c -0.33867,0.2286001 -0.70274,0.3640669 -1.15147,0.3640669 -0.87207,0 -1.4732,-0.6265336 -1.524,-1.6086673 h 3.0988 c 0.0169,-0.1100668 0.0339,-0.2709335 0.0339,-0.4402669 0,-1.3123338 -0.6604,-2.1590008 -1.8288,-2.1590008 -1.0668,0 -2.07434,0.9313337 -2.07434,2.4214676 0,1.5155339 0.98214,2.4045343 2.20134,2.4045343 z m -1.43934,-2.7770678 c 0.0931,-0.9059337 0.66887,-1.4308672 1.3208,-1.4308672 0.71967,0 1.143,0.4995335 1.143,1.4308672 z"
                        />
                     <path
                        d="m 129.08505,10.102066 c 0.65193,0 1.2446,-0.1524002 1.79493,-0.4826003 l -0.2032,-0.4572002 c -0.42333,0.2455334 -0.9652,0.4233335 -1.54093,0.4233335 -1.60867,0 -2.81093,-1.0498671 -2.81093,-2.8956011 0,-2.2098009 1.6256,-3.6491348 3.31046,-3.6491348 1.71874,0 2.62467,1.1176004 2.62467,2.6500677 0,1.2276671 -0.67733,1.9473341 -1.27847,1.9473341 -0.51646,0 -0.69426,-0.3471335 -0.51646,-1.1091338 l 0.37253,-1.8880674 h -0.508 l -0.11007,0.3894668 h -0.0169 c -0.16933,-0.3132668 -0.4318,-0.4656668 -0.75353,-0.4656668 -1.10914,0 -1.82034,1.1938005 -1.82034,2.1928675 0,0.8636004 0.49107,1.3462006 1.13454,1.3462006 0.41486,0 0.84666,-0.2878668 1.15146,-0.6519336 h 0.0254 c 0.0593,0.4826001 0.4572,0.7196669 0.97367,0.7196669 0.85514,0 1.88807,-0.8636003 1.88807,-2.514601 0,-1.8626674 -1.20227,-3.1326679 -3.10727,-3.1326679 -2.1082,0 -3.94547,1.6594673 -3.94547,4.1910017 0,2.2013342 1.48167,3.3866681 3.33587,3.3866681 z m -0.16933,-2.5315345 c -0.381,0 -0.66887,-0.2455334 -0.66887,-0.8551336 0,-0.7112003 0.4572,-1.6086673 1.20227,-1.6086673 0.254,0 0.4318,0.1100667 0.6096,0.3979334 l -0.27094,1.5070673 c -0.32173,0.3894668 -0.6096,0.5588002 -0.87206,0.5588002 z"
                        />
                     <path
                        d="m 135.61286,10.753999 c 1.4224,0 2.31987,-0.7366 2.31987,-1.5917335 0,-0.7535336 -0.5334,-1.0922004 -1.6002,-1.0922004 h -0.89747 c -0.61807,0 -0.80433,-0.2032001 -0.80433,-0.4995336 0,-0.2540001 0.127,-0.4064001 0.29633,-0.5503335 0.2032,0.1016 0.4572,0.1608667 0.6858,0.1608667 0.93133,0 1.6764,-0.6180669 1.6764,-1.6002006 0,-0.3979335 -0.1524,-0.7366003 -0.37253,-0.9482671 h 0.9398 V 4.0399301 h -1.6002 c -0.16087,-0.059267 -0.38947,-0.1185333 -0.64347,-0.1185333 -0.93133,0 -1.7272,0.6350002 -1.7272,1.6425339 0,0.5503336 0.29633,0.9906004 0.60113,1.2361339 v 0.033867 c -0.23706,0.1693334 -0.51646,0.4741336 -0.51646,0.8551337 0,0.3640668 0.1778,0.6096003 0.42333,0.7535337 v 0.033867 c -0.4318,0.2794001 -0.6858,0.6519336 -0.6858,1.0414004 0,0.7958674 0.77893,1.2361334 1.905,1.2361334 z m 0,-4.0978678 c -0.5334,0 -0.98213,-0.4233335 -0.98213,-1.0922005 0,-0.6773336 0.44026,-1.0752671 0.98213,-1.0752671 0.54187,0 0.97367,0.4064002 0.97367,1.0752671 0,0.668867 -0.44874,1.0922005 -0.97367,1.0922005 z m 0.11007,3.5729348 c -0.8382,0 -1.33774,-0.3217335 -1.33774,-0.8128004 0,-0.2709334 0.14394,-0.5418669 0.47414,-0.7789336 0.2032,0.0508 0.42333,0.0762 0.59266,0.0762 h 0.79587 c 0.6096,0 0.9398,0.1439334 0.9398,0.5672669 0,0.4826002 -0.5842,0.9482671 -1.46473,0.9482671 z"
                        />
                     <path
                        d="m 138.83018,8.637332 h 0.77047 V 5.301464 c 0.42333,-0.4741336 0.8128,-0.7027336 1.15993,-0.7027336 0.5842,0 0.85514,0.3640668 0.85514,1.2276671 V 8.637332 h 0.77046 V 5.301464 c 0.4318,-0.4741336 0.80434,-0.7027336 1.15994,-0.7027336 0.5842,0 0.85513,0.3640668 0.85513,1.2276671 V 8.637332 h 0.77047 V 5.7247975 c 0,-1.1768672 -0.44874,-1.8034007 -1.397,-1.8034007 -0.5588,0 -1.0414,0.3640668 -1.51554,0.8805336 -0.18626,-0.5418668 -0.56726,-0.8805336 -1.27,-0.8805336 -0.55033,0 -1.03293,0.3471334 -1.43086,0.7874003 h -0.0254 l -0.0677,-0.668867 h -0.635 z"
                        />
                     <path
                        d="m 147.72867,8.7473987 c 0.56726,0 1.08373,-0.2963335 1.524,-0.6604003 h 0.0254 l 0.0677,0.5503336 h 0.635 V 5.8094642 c 0,-1.1345338 -0.47413,-1.8880674 -1.59173,-1.8880674 -0.7366,0 -1.38854,0.3302001 -1.81187,0.6011335 l 0.3048,0.5334002 c 0.36406,-0.2455334 0.84667,-0.4910668 1.38007,-0.4910668 0.75353,0 0.94826,0.5672669 0.94826,1.1599338 -1.9558,0.2201334 -2.82787,0.7196669 -2.82787,1.718734 0,0.8297337 0.57574,1.3038672 1.34621,1.3038672 z m 0.22013,-0.6180669 c -0.4572,0 -0.8128,-0.2116668 -0.8128,-0.7366003 0,-0.5926669 0.52493,-0.9736671 2.07433,-1.1599338 v 1.2869338 c -0.44873,0.3979335 -0.8128,0.6096003 -1.26153,0.6096003 z"
                        />
                     <path
                        d="m 151.44552,8.637332 h 0.77047 V 4.0399301 h -0.77047 z m 0.38947,-5.5456689 c 0.3048,0 0.5334,-0.2116668 0.5334,-0.4995335 0,-0.3132668 -0.2286,-0.4995336 -0.5334,-0.4995336 -0.3048,0 -0.5334,0.1862668 -0.5334,0.4995336 0,0.2878667 0.2286,0.4995335 0.5334,0.4995335 z"
                        />
                     <path
                        d="m 154.58665,8.7473987 c 0.2032,0 0.33866,-0.0254 0.44873,-0.067733 l -0.11007,-0.5926669 c -0.0847,0.016933 -0.11853,0.016933 -0.16086,0.016933 -0.11854,0 -0.22014,-0.093133 -0.22014,-0.3302002 v -5.875869 h -0.77046 v 5.825069 c 0,0.6519336 0.23706,1.0244671 0.8128,1.0244671 z"
                        />
                     <path
                        d="m 156.58479,8.7473987 c 0.3048,0 0.5588,-0.2370668 0.5588,-0.5842003 0,-0.3471334 -0.254,-0.6011335 -0.5588,-0.6011335 -0.3048,0 -0.5588,0.2540001 -0.5588,0.6011335 0,0.3471335 0.254,0.5842003 0.5588,0.5842003 z"
                        />
                     <path
                        d="m 160.24238,8.7473987 c 0.55033,0 1.07526,-0.2116668 1.48166,-0.5842003 l -0.3302,-0.5164668 c -0.28786,0.2624667 -0.6604,0.4572002 -1.08373,0.4572002 -0.84667,0 -1.4224,-0.7027337 -1.4224,-1.7610674 0,-1.0583338 0.6096,-1.7780007 1.43933,-1.7780007 0.37254,0 0.65194,0.1693334 0.92287,0.4148668 l 0.39793,-0.5164669 c -0.3302,-0.2878667 -0.74506,-0.5418668 -1.3462,-0.5418668 -1.17686,0 -2.21826,0.8890003 -2.21826,2.4214676 0,1.5240006 0.9398,2.4045343 2.159,2.4045343 z"
                        />
                     <path
                        d="m 164.32331,8.7473987 c 1.12607,0 2.13361,-0.8805337 2.13361,-2.4045343 0,-1.5324673 -1.00754,-2.4214676 -2.13361,-2.4214676 -1.12606,0 -2.1336,0.8890003 -2.1336,2.4214676 0,1.5240006 1.00754,2.4045343 2.1336,2.4045343 z m 0,-0.6434669 c -0.79586,0 -1.32926,-0.7027337 -1.32926,-1.7610674 0,-1.0583338 0.5334,-1.7780007 1.32926,-1.7780007 0.79587,0 1.33774,0.7196669 1.33774,1.7780007 0,1.0583337 -0.54187,1.7610674 -1.33774,1.7610674 z"
                        />
                     <path
                        d="m 167.66766,8.637332 h 0.77047 V 5.301464 c 0.42333,-0.4741336 0.8128,-0.7027336 1.15993,-0.7027336 0.5842,0 0.85514,0.3640668 0.85514,1.2276671 V 8.637332 h 0.77046 V 5.301464 c 0.4318,-0.4741336 0.80434,-0.7027336 1.15994,-0.7027336 0.5842,0 0.85513,0.3640668 0.85513,1.2276671 V 8.637332 h 0.77047 V 5.7247975 c 0,-1.1768672 -0.44874,-1.8034007 -1.397,-1.8034007 -0.5588,0 -1.0414,0.3640668 -1.51554,0.8805336 -0.18626,-0.5418668 -0.56726,-0.8805336 -1.27,-0.8805336 -0.55033,0 -1.03293,0.3471334 -1.43087,0.7874003 h -0.0254 l -0.0677,-0.668867 h -0.635 z"
                        />
                   </g>
                 </g>
               </svg>
            </span>
        </div>
    );
}

export default AppFooter;
