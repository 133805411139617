import React, { useState } from 'react';

import { useSelector } from 'react-redux';

import { RootStateType } from "..";

import { Row, Col, Divider, Button } from 'antd';

import AppLayout from '../components/app_layout';
import RoleEditor from '../components/role_editor';

import PyCAT_logo from '../resources/image/PyCat_icon_269x269_72DPI.png';
// import minitee_logo from '../resources/image/minitee_layout_top_logo_outlined.svg';
import Notice from '../components/notice';

import '../css/home.css';

function Home() {

    const isLoggedIn: boolean = useSelector<RootStateType, boolean>(state => state.auth.isLoggedIn);

    const [numOfNoticeItems, setNumOfNoticeItems] = useState<number>(0);
    const [showNoticeBtnLabel, setShowNoticeBtnLabel] = useState<string>('공지 감추기');

    function setNoticeItemLength(value: number){
        setNumOfNoticeItems(value);
    }

    function onClickShowNoticeBtnHandler(event: React.MouseEvent) {
        event.preventDefault();

        const noticeDiv = document.getElementById('notice_div');

        // if(noticeDiv){
        //     noticeDiv.classList.toggle('display');
        //     noticeDiv.classList.toggle('hide');
        // }

        if(showNoticeBtnLabel === '공지 보기'){
            if(noticeDiv){
                // noticeDiv.style.display = 'block';
                // noticeDiv.style.height = `${noticeDiv.offsetHeight}px`;
                noticeDiv.style.height = `${noticeDiv.scrollHeight}px`;
            }
            setShowNoticeBtnLabel('공지 감추기');
        }
        else{
            if(noticeDiv){
                // noticeDiv.style.display = 'none';
                noticeDiv.style.height = '0px';
            }
            setShowNoticeBtnLabel('공지 보기');
        }
    }

    return(
        <AppLayout>
            <div id="home_top_container_div">
                {!isLoggedIn &&
                    <>
                        <Row id="home_pycat_icon_display_div" justify={'center'}>
                            <Col
                                xs={{span: 4}} sm={{span: 4}} md={{span: 4}}
                                lg={{span: 4}} xl={{span: 4}} xxl={{span: 6}}               
                            >
                            </Col>
                            <Col
                                xs={{span: 16}} sm={{span: 16}} md={{span: 16}}
                                lg={{span: 16}} xl={{span: 16}} xxl={{span: 12}}
                            >
                                <a href="/user/signin">
                                    <img src={PyCAT_logo} data-page="home-main" alt="PyCAT Logo"></img>
                                {/* <img src={minitee_logo} data-page="home" alt="Logo"></img> */}
                                </a>
                            </Col>
                            <Col
                                xs={{span: 4}} sm={{span: 4}} md={{span: 4}}
                                lg={{span: 4}} xl={{span: 4}} xxl={{span: 6}}               
                            >
                            </Col>
                        </Row>
                        <div id="home_notice_div">
                            <Row id="home_divider_div">
                                <Col
                                    xs={{span: 1}} sm={{span: 1}} md={{span: 1}}
                                    lg={{span: 4}} xl={{span: 4}} xxl={{span: 5}}               
                                >
                                </Col>
                                <Col
                                    xs={{span: 22}} sm={{span: 22}} md={{span: 22}}
                                    lg={{span: 16}} xl={{span: 16}} xxl={{span: 14}}
                                >
                                    <Divider></Divider>
                                </Col>
                                <Col
                                    xs={{span: 1}} sm={{span: 1}} md={{span: 1}}
                                    lg={{span: 4}} xl={{span: 4}} xxl={{span: 5}}               
                                >
                                </Col>
                            </Row>
                            <Row id="home_notice_contents_div">
                                <Col
                                    xs={{span: 1}} sm={{span: 1}} md={{span: 1}}
                                    lg={{span: 4}} xl={{span: 4}} xxl={{span: 5}}               
                                >
                                </Col>
                                <Col
                                    xs={{span: 22}} sm={{span: 22}} md={{span: 22}}
                                    lg={{span: 16}} xl={{span: 16}} xxl={{span: 14}}
                                >
                                    <Notice reportItemLength={setNoticeItemLength}></Notice>
                                </Col>
                                <Col
                                    xs={{span: 1}} sm={{span: 1}} md={{span: 1}}
                                    lg={{span: 4}} xl={{span: 4}} xxl={{span: 5}}               
                                >
                                </Col>
                            </Row>
                            <Row id="home_notice_button_div">
                                <Col
                                    xs={{span: 24}} sm={{span: 24}} md={{span: 24}}
                                    lg={{span: 24}} xl={{span: 24}} xxl={{span: 24}}               
                                >
                                    {numOfNoticeItems > 0 &&
                                        <div id="agora_show_notice_button_div">
                                            <Button type="primary" onClick={onClickShowNoticeBtnHandler}>{showNoticeBtnLabel}</Button>
                                        </div>
                                    }    
                                </Col>
                            </Row>
                        </div>
                    </>
                }
                {isLoggedIn &&
                    <Row id="home_system_role_edit_div">
                        <Col
                            xs={{span: 24}} sm={{span: 24}} md={{span: 24}}
                            lg={{span: 24}} xl={{span: 24}} xxl={{span: 24}}               
                        >
                            {/* <h3>Main contents here</h3> */}
                            <RoleEditor></RoleEditor>
                        </Col>
                    </Row>
                }
            </div>
        </AppLayout>
    );
}

export default Home;
