import React, { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { RootStateType } from "..";

import { Button, Collapse, Divider, Input, Modal, Upload, Checkbox, CollapseProps } from 'antd';
import { PlusOutlined } from "@ant-design/icons";
import { UploadFile } from "antd/lib/upload/interface";
import { RcFile } from "rc-upload/lib/interface";
import { UploadRequestOption } from "rc-upload/lib/interface";

import AppLayout from "../components/app_layout";
import '../css/signup.css';

import { ImageResizer }  from "../components/utils";
import { CheckboxChangeEvent } from "antd/lib/checkbox";

function Signup(): JSX.Element {

    // type fileListObjType = {
    //     uid: string,
    //     name: string,
    //     status: string,
    //     url: string
    // };

    type fileListObjType = UploadFile<unknown>;

    const navigate = useNavigate();

    const isLoggedIn: boolean = useSelector<RootStateType, boolean>(state => state.auth.isLoggedIn);

    const { Panel } = Collapse;

    let [email, setEmail] = useState('');
    let [emailMessage, setEmailMessage] = useState('');
    let [username, setUsername] = useState('');
    let [usernameMessage, setUsernameMessage] = useState('');
    let [mobile, setMobile] = useState('');
    let [mobileMessage, setMobileMessage] = useState('');
    let [passwd, setPasswd] = useState('');
    let [passwdMessage, setPasswdMessage] = useState('');
    let [confirmPW, setConfirmPW] = useState('');
    let [confirmPWMessage, setConfirmPWMessage] = useState('');

    let [imageMessage, setImageMessage] = useState('');
    let [previewVisible, setPreviewVisible] = useState(false);
    let [previewImage, setPreviewImage] = useState('');
    let [previewTitle, setPreviewTitle] = useState('');
    let [fileList, setFileList] = useState<Array<fileListObjType>>([]);
    // let uploadedFileList = useRef<Array<string>>([]);
    const isImageValid = useRef(true);

    let [signupComplete, setSignupComplete] = useState<Boolean>(false);
    // let [signupComplete, setSignupComplete] = useState<Boolean>(true);

    // const [policyE, setPolicyE] = useState<JSX.Element>(<></>);
    const [policyE, setPolicyE] = useState<Array<JSX.Element>>([]);
    const [termsE, setTermsE] = useState<Array<JSX.Element>>([]);
    const [agreeOnTOC, setAgreeOnTOC] = useState<boolean>(false);

    let [resizedImage, setResizedImage] = useState<JSX.Element>(<></>);

    const onChangeEmailHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        setEmail(event.currentTarget.value);

        let pat0 = /^[A-Za-z]{1}[A-Za-z0-9\.\-_]+@[A-Za-z0-9\-_]+\.[a-z]{1,8}\.?[a-z]{1,6}$/;
        if(pat0.test(event.currentTarget.value)){
            setEmailMessage('');
        }
        else{
            setEmailMessage('이메일 주소를 확인해주세요.');
        }
    };

    const onChangeUsernameHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        setUsername(event.currentTarget.value);
        if(event.currentTarget.value.length < 5) {
            setUsernameMessage('사용자 이름을 5글자 이상 입력해주세요.');
        }
        else {
            setUsernameMessage('');
        }
    };

    const onChangeMobileHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();

        let pat0 = /[^0-9]+/g;
        let rawVal = event.currentTarget.value;
        let numVal = rawVal.replace(pat0, '');

        setMobile(numVal);
        if(numVal.length != 0){
            if(numVal.length < 10) {
                setMobileMessage('전화번호를 확인해 주세요.')
            }
            else {
                setMobileMessage('');
            }
        }
        else{
            setMobileMessage('');
        }
    };

    const onChangePasswdHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        setPasswd(event.currentTarget.value);
        if(event.currentTarget.value.length < 8) {
            setPasswdMessage('암호가 8글자 이상인지 확인해주세요.')
        }
        else {
            setPasswdMessage('');
        }
    };

    const onChangeConfirmPWHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        setConfirmPW(event.currentTarget.value);
        if(event.currentTarget.value !== passwd) {
            setConfirmPWMessage('입력하신 암호가 일치하는지 확인해주세요.')
        }
        else {
            setConfirmPWMessage('');
        }
    };

    // function getBase64(file: Blob) {
    const getBase64 = (file: Blob) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    };

    const previewResizedImage = (imageBlob: Blob) => {
        // console.log('Signup.previewResizedImage:', imageBlob);

        let reader = new FileReader();

        reader.onload = (event) => {
            if(event.target && typeof event.target.result === 'string'){
                setResizedImage(<img src={event.target.result}></img>);
            }
        };

        reader.readAsDataURL(imageBlob);
    }

    const handleBeforeUpload = (file: RcFile) => {

        // console.log('Signup.handleBeforeUpload.file:', file);

        const isJpgOrPng = file ? (file.type === 'image/jpeg' || file.type === 'image/png') : false;
        if(!isJpgOrPng){
            setImageMessage('JPG 또는 PNG 이미지인지 확인해주세요.')
        }
        const isSizeOk = file ? ((file.size>>20) < 10) : false; //  approximation of /(1024*1024)
        if(!isSizeOk){
            setImageMessage('크기 10M 이하의 파일만 업로드 가능합니다.')
        }

        // console.log('handleBeforeUpload.(isJpgOrPng:%s, isSizeOk:%s)', isJpgOrPng, isSizeOk);

        // ImageResizer.resize(file, 235, 235, previewResizedImage);

        // ImageResizer.resize(file, 235, 235).then((result) => {
        //     console.log('Signup.handleBeforeUpload.ImageResizer.result:', result);
        //     previewResizedImage(result);
        // }).catch((error) => {
        //     console.log('[Error]Signup.handleBeforeUpload.ImageResizer.resize:', error)
        // });

        isImageValid.current = isJpgOrPng&&isSizeOk;

        return (isJpgOrPng&&isSizeOk);
    };

    // const uploadDone = () => {
    //     let storedList = uploadDoneList;
    //     console.log('uploadDone.storedList:', storedList);
    //     storedList.push(true);
    //     setUploadDoneList(storedList);
    //     console.log('uploadDone.uploadDoneList:', uploadDoneList);
    // }

    // const handleUpload = (file: UploadProps<unknown>) => {
    // const handleUpload = (reqObj: UploadRequestOption<unknown>) => {

    //     let { onSuccess } = reqObj;

    //     let fileObj: RcFile = reqObj.file as RcFile;
    //     let uid = fileObj.uid;
    //     console.log('handleUpload.file.uid:', uid);

    //     console.log('handleUpload.fileList:', fileList);
    //     // console.log('handleUpload.uploadFileList:', uploadFileList.current);

    //     // let index = 0;
    //     let index = fileList.length;

    //     // uploadFileList.current.forEach((item, idx) => {
    //     //     if(item.uid === uid){
    //     //         // console.log('handleUpload.uploadFileList.item.uid:', item.uid);
    //     //         // console.log('handleUpload.uploadFileList.item:', item);
    //     //         console.log('handleUpload.uploadFileList.item.thumbUrl:', item.thumbUrl);
    //     //         index = idx;
    //     //     }
    //     // });

    //     fileList.forEach((item, idx) => {
    //         if(item.uid === uid){
    //             // console.log('handleUpload.uploadFileList.item.uid:', item.uid);
    //             // console.log('handleUpload.uploadFileList.item:', item);
    //             console.log('handleUpload.fileList.item.thumbUrl:', item.thumbUrl);
    //             index = idx;
    //         }
    //     });

    //     // console.log('handleUpload.uploadFileList.targetIndex:', index);

    //     let formData = new FormData();

    //     formData.append("file", reqObj.file);

    //     fetch('/api/user/uploadProfilePic', {
    //         method: 'POST',
    //         body: formData
    //     }).then((response) => {
    //         return response.json();
    //     }).then((resBody) => {
    //         // uploadFileList.current[index].percent = 100;
    //         // uploadFileList.current[index].status = 'done';
    //         // fileList[index].percent = 100;
    //         // fileList[index].status = 'done';
    //         // console.log('handleUpload.uploadFileList:', uploadFileList.current);
    //         if(onSuccess) {
    //             // console.log('handleUpload.onSuccess:', onSuccess);
    //             // onSuccess((body: Object) => {
    //             //     console.log('handleUpload.onSuccess.body:', body);
    //             // });
    //             // onSuccess('OK');
    //             onSuccess(resBody);
    //         }
    //         uploadedFileList.current.push(resBody["filename"]);
    //         console.log('handleUpload.fileList:', fileList);
    //         console.log('Signup.handleUpload:', resBody);
    //     }).catch((error) => {
    //         console.log('[Error]Signup.handleUpload: ', error);
    //     });
    // }

    const handleUpload = (reqOpt: UploadRequestOption<unknown>) => {
        let { onSuccess } = reqOpt;

        let fileObj: RcFile = reqOpt.file as RcFile;
        let uid = fileObj.uid;
        // console.log('handleUpload.file.uid:', uid);

        //  onSuccess should be called somewhere outside the current run loop.
        setTimeout(() => {
            if(onSuccess){
                onSuccess(uid);
            }
        }, 0);
    };

    const handleCancel = () => {
        setPreviewVisible(false);
    };

    const handlePreview = async (file: any) => {
        if(!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }

        setPreviewImage(file.url || file.preview);
        setPreviewVisible(true);
        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    };

    const handleChange = ({fileList}: {fileList: Array<fileListObjType>}) => {
        setFileList(fileList);
        // console.log('Signup.handleChange.fileList:', fileList);
    };

    // function getRandomArbitraryInt(min: number, max: number) {
    //     return Math.floor(Math.random()*(max - min) + min);
    // }

    // function getUuid() {
    //     let now = Date.now();
    //     let rn = getRandomArbitraryInt(0, 9999);

    //     return `${now}-${rn}`;
    // }

    const uploadButton = (
        <div>
            <div style={{ marginTop: 24 }}>
                <PlusOutlined/>
            </div>
            <div style={{ marginTop: 4 }}>
                Profile 사진
            </div>
        </div>
    );

    // const handleRemove = (file: UploadFile<unknown>): boolean => {
    //     console.log('handleRemove.file under remove:', file);
    //     console.log('handleRemove.file under remove.uid:', file.uid);
    //     console.log('handleRemove.file under remove.name:', file.name);
    //     console.log('handleRemove.fileList:', fileList);

    //     let uid = file.uid;
    //     let index = 0;

    //     fileList.forEach((item, idx) => {
    //         if(item.uid === uid){
    //             index = idx;
    //         }
    //     });

    //     fileList.splice(index, 1);

    //     return true;
    // };

    const checkSubmitValidity = async () => {
        let pat0 = /^[A-Za-z]{1}[A-Za-z0-9\.\-_]+@[A-Za-z0-9\-_]+\.[a-z]{1,8}\.?[a-z]{1,6}$/;

        let isPWValid = (passwd.length >= 8) && (passwd === confirmPW) ? true : false;
        let isEmailValid = pat0.test(email);
        let isUsernameValid = username.length >=5 ? true : false;
        let isMobileValid = mobile.length == 0 ? true : (mobile.length >= 10 ? true : false);

        console.log('Signup.checkSubmitValidity.(isPWValid: %s, isEmailValid: %s, isUsernameValid: %s, isMobileValid: %s)', isPWValid, isEmailValid, isUsernameValid, isMobileValid);

        let retVal = false;

        if(isPWValid && isEmailValid && isUsernameValid && isMobileValid && isImageValid.current){

            let data = {
                email: email,
                username: username,
                mobile: mobile
            }

            // await fetch('/api/user/checkregdata', {
            //     method: 'POST',
            //     headers: {"content-type": 'application/json'},
            //     body: JSON.stringify(data)
            // }).then((response) => {
            //     return response.json();
            // }).then((resBody) => {
            //     retVal = resBody["isValid"];
            //     setEmailMessage(resBody["emailMsg"]);
            //     setUsernameMessage(resBody["usernameMsg"]);
            //     setMobile(resBody["mobileMsg"]);
            //     console.log('Signup.checkSubmitValidity.resBody:', resBody);
            // }).catch((error) => {
            //     console.log('[Error]Signup.checkSubmitValidity:', error);
            // });

            const checkResponse = await fetch('/api/user/checkregdata', {
                method: 'POST',
                headers: {"content-type": 'application/json'},
                body: JSON.stringify(data)
            });

            return checkResponse.json();
        }
        else {
            return {"isValid": retVal, "emailMsg": '', "usernameMsg": '', "mobileMsg": ''};
        }
    };

    type checkregdataResBodyType = {
        isValid: boolean
        , emailMsg: string
        , usernameMsg: string
        , mobileMsg: string
    };

    const onSubmitHandler = async () => {

        // checkSubmitValidity().then((resBody) => {
        //     console.log('onSubmitHandler.checkSubmitValidity.resBody:', resBody);
        //     setEmailMessage(resBody["emailMsg"]);
        //     setUsernameMessage(resBody["usernameMsg"]);
        //     setMobile(resBody["mobileMsg"]);
        //     // if(resBody["isValid"] === true || resBody["isValid"] === 'true' || resBody["isValid"] === 'True'){
        //     if(resBody["isValid"] === true){
        //         let formData = new FormData();

        //         formData.append("email", email);
        //         formData.append("username", username);
        //         formData.append("mobile", mobile);
        //         formData.append("password", passwd);

        //         fileList.forEach(({originFileObj}) => {
        //             if(originFileObj){
        //                 formData.append("imagefile", originFileObj as Blob);
        //             }
        //         });

        //         fetch('/api/user/register', {
        //             method: 'POST',
        //             body: formData
        //         }).then((response) => {
        //             return response.json();
        //         }).then((resBody) => {
        //             console.log('onSubmitHandler.fetch().then():', resBody);
        //             navigate('/user/signupcomplete');
        //         }).catch((error) => {
        //             console.log('[Error]onSubmitHandler.fetch(): ', error);
        //         });
        //     }
        // }).catch((error) => {
        //     console.log('[Error]onSubmitHandler.checkSubmitValidity():', error)
        // });

        // console.log('onSubmitHandler()');

        const submitValidity: checkregdataResBodyType = await checkSubmitValidity();
        // console.log('onSubmitHandler.submitValidity:', submitValidity);

        setEmailMessage(submitValidity["emailMsg"]);
        setUsernameMessage(submitValidity["usernameMsg"]);
        setMobileMessage(submitValidity["mobileMsg"]);

        if(submitValidity["isValid"] && agreeOnTOC === true){
            let formData = new FormData();

            formData.append("email", email);
            formData.append("username", username);
            formData.append("mobile", mobile);
            formData.append("password", passwd);

            // fileList.forEach(async ({originFileObj}) => {
            //     if(originFileObj){
            //         // formData.append("profPicFile", originFileObj as Blob);

            //         // ImageResizer.resize(originFileObj, 235, 235).then((result) => {
            //         //     console.log('Signup.onSubmitHandler.ImageResizer.result:', result);
            //         //     // previewResizedImage(result);

            //         //     let resizedFile = new File([result], originFileObj.name, {type: originFileObj.type, lastModified: originFileObj.lastModified});
            //         //     console.log('Signup.onSubmitHandler.ImageResizer.resizedFile:', resizedFile);

            //         //     formData.append("profPicFile", resizedFile as Blob);
            //         // }).catch((error) => {
            //         //     console.log('[Error]Signup.onSubmitHandler.ImageResizer.resize:', error)
            //         // });

            //         try{
            //             let result = await ImageResizer.resize(originFileObj, 235, 235);
            //             console.log('Signup.onSubmitHandler.ImageResizer.result:', result);

            //             let resizedFile = new File([result], originFileObj.name, {type: originFileObj.type, lastModified: originFileObj.lastModified});
            //             console.log('Signup.onSubmitHandler.ImageResizer.resizedFile:', resizedFile);

            //             formData.append("profPicFile", resizedFile as Blob);
            //         }
            //         catch(error){
            //             console.log('[Error]Signup.onSubmitHandler.ImageResizer.resize:', error)
            //         }
            //     }
            // });

            for (let item of fileList){
                let {originFileObj} = item;

                if(originFileObj){
                    try{
                        let result = await ImageResizer.resize(originFileObj, 235, 235);
                        // console.log('Signup.onSubmitHandler.ImageResizer.originFileObj:', originFileObj);

                        let resizedFile = new File([result], originFileObj.name, {type: originFileObj.type, lastModified: originFileObj.lastModified});
                        // console.log('Signup.onSubmitHandler.ImageResizer.resizedFile:', resizedFile);


                        formData.append("profPicFile", resizedFile as Blob);
                    }
                    catch(error){
                        console.log('[Error]Signup.onSubmitHandler.ImageResizer.resize:', error)
                    }
                }
            }
          
            const response = await fetch('/api/user/register', {
                method: 'POST',
                body: formData
            });

            // console.log('onSubmitHandler.response:', response);

            const resBody = await response.json();

            // console.log('onSubmitHandler.resBody:', resBody);

            // navigate('/user/signupcomplete');
            setSignupComplete(true);
        }

    };

    const onCancelHandler = () => {
        navigate('/home');
    };

    const onHandleSignin = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        navigate('/user/signin')
    }

    function onClickTermsOfContractCheckbox(event: CheckboxChangeEvent) {
        // event.preventDefault();

        // console.log('onClickTermsOfContractCheckbox.checked:', event.target.checked);

        setAgreeOnTOC(event.target.checked);
    }


    type privacyPolicyStatementType = {
        numLeadingSpaces: number
        , isBold: number
        , content: string
        , numFollowingEmptyLines: number
    };

    type getprivacypolicyResBodyType = {
        success: boolean
        , policy: Array<privacyPolicyStatementType>
    };

    type termsofcontractStatementType = privacyPolicyStatementType;
    type gettermsofcontractResBodyType = {
        success: boolean
        , terms: Array<termsofcontractStatementType>
    };

    useEffect(() => {
        if(isLoggedIn){
            navigate('/home');
        }
        else{
            fetch('/api/user/getprivacypolicy', {
                method: 'GET'
            }).then((response) => {
                return response.json();
            }).then((resBody: getprivacypolicyResBodyType) => {
                if(resBody["success"] === true){
                    const notice = resBody["policy"].map((item: privacyPolicyStatementType, index: number) => {
                        // let policyStr = '';

                        // for(let i=0; i<item["numLeadingSpaces"]; i++){
                        //     policyStr = policyStr.concat('&nbsp;');
                        // }

                        // if(item["isBold"] === 1){
                        //     policyStr = policyStr.concat('<b>', item["content"], '</b>');
                        // }
                        // else{
                        //     policyStr = policyStr.concat(item["content"]);
                        // }

                        // policyStr = policyStr.concat('<br>');

                        // for(let i=0; i<item["numFollowingEmptyLines"]; i++){
                        //     policyStr = policyStr.concat('<br>');
                        // }

                        // return(
                        //     <p key={index}>
                        //         {policyStr}
                        //     </p>
                        // )

                        let statementE: Array<JSX.Element> = []

                        for(let i=0; i<item["numLeadingSpaces"]; i++){
                            statementE.push(<span key={statementE.length}>&nbsp;</span>)
                        }

                        if(item["isBold"] === 1){
                            statementE.push(<span key={statementE.length}><b>{item["content"]}</b></span>);
                        }
                        else{
                            statementE.push(<span key={statementE.length}>{item["content"]}</span>);
                        }

                        statementE.push(<br key={statementE.length}></br>);

                        for(let i=0; i<item["numFollowingEmptyLines"]; i++){
                            statementE.push(<br key={statementE.length}></br>);
                        }

                        // console.log('Signup.useEffect.getprivacypolicy.statementE:', statementE);

                        return(
                            <p key={index}>
                                {statementE}
                            </p>
                        )
                    });

                    // console.log('Signup.useEffect.getprivacypolicy.notice:', notice);

                    setPolicyE(notice);
                }
            }).catch((error) => {
                console.log('[Error]Signup.useEffect.getprivacypolicy:', error);
            });
        }
    }, [isLoggedIn]);

    // useEffect(() => {
    //     console.log('useEffect.fileList:', fileList);
    // }, [fileList]);

    // useEffect(() => {
    //     console.log('useEffect.uploadedFileList:', uploadedFileList.current);
    // }, [uploadedFileList.current]);

    useEffect(() => {
        if(isLoggedIn){
            navigate('/home');
        }
        else{
            fetch('/api/user/gettermsofcontract', {
                method: 'GET'
            }).then((response) => {
                return response.json();
            }).then((resBody: gettermsofcontractResBodyType) => {
                if(resBody["success"] === true){
                    const terms = resBody["terms"].map((item: termsofcontractStatementType, index: number) => {
                        let statementE: Array<JSX.Element> = []

                        for(let i=0; i<item["numLeadingSpaces"]; i++){
                            statementE.push(<span key={statementE.length}>&nbsp;</span>)
                        }

                        if(item["isBold"] === 1){
                            statementE.push(<span key={statementE.length}><b>{item["content"]}</b></span>);
                        }
                        else{
                            statementE.push(<span key={statementE.length}>{item["content"]}</span>);
                        }

                        statementE.push(<br key={statementE.length}></br>);

                        for(let i=0; i<item["numFollowingEmptyLines"]; i++){
                            statementE.push(<br key={statementE.length}></br>);
                        }

                        // console.log('Signup.useEffect.gettermsofcontract.statementE:', statementE);

                        return(
                            <p key={index}>
                                {statementE}
                            </p>
                        )
                    });

                    // console.log('Signup.useEffect.gettermsofcontract.notice:', notice);

                    setTermsE(terms);
                }
            }).catch((error) => {
                console.log('[Error]Signup.useEffect.gettermsofcontract:', error);
            });
        }
    }, [isLoggedIn]);

    const signupTermsOfContractItems: CollapseProps["items"] = [
        {
            key: '0'
            , label: '서비스 이용 약관'
            , children: termsE
        }
    ];

    const signupPrivacyPolicyItems: CollapseProps["items"] = [
        {
            key: '0'
            , label: '개인정보 보호정책'
            , children: policyE
        }
    ];

    return (
        <AppLayout>
            <div id="signup_top_container_div">
                {!isLoggedIn && !signupComplete &&
                    <div>
                        <div id="signup_page_title_div">Sign Up</div>
                        {/* <form onSubmit={onSubmitHandler}> */}
                        <form>
                            <div id="signup_email_container_div" className="signup_input_container_div">
                                <Input value={email} onChange={onChangeEmailHandler} addonBefore="Email*"></Input>
                                <div id="signup_email_msg_div" className="signup_msg_div">
                                    {emailMessage}
                                </div>
                            </div>
                            <div id="signup_username_container_div" className="signup_input_container_div">
                                <Input value={username} onChange={onChangeUsernameHandler} addonBefore="Username*"></Input>
                                <div id="signup_username_msg_div" className="signup_msg_div">
                                    {usernameMessage}
                                </div>
                            </div>
                            <div id="signup_mobile_container_div" className="signup_input_container_div">
                                <Input value={mobile} onChange={onChangeMobileHandler} addonBefore="Mobile" placeholder="숫자만 입력해주세요"></Input>
                                <div id="signup_mobile_msg_div" className="signup_msg_div">
                                    {mobileMessage}
                                </div>
                            </div>
                            <div id="signup_passwd_container_div" className="signup_input_container_div">
                                <Input.Password className="password" value={passwd} onChange={onChangePasswdHandler} addonBefore="Password*"></Input.Password>
                                <div id="signup_passwd_msg_div" className="signup_msg_div">
                                    {passwdMessage}
                                </div>
                            </div>
                            <div id="signup_confirmpw_container_div" className="signup_input_container_div">
                                <Input.Password className="password" value={confirmPW} onChange={onChangeConfirmPWHandler} addonBefore="Password 확인*"></Input.Password>
                                <div id="signup_confirmpw_msg_div" className="signup_msg_div">
                                    {confirmPWMessage}
                                </div>
                            </div>
                            <div id="signup_image_upload_container_div" className="signup_input_container_div">
                                <Upload 
                                    // action="/api/user/uploadProfilePic"
                                    customRequest={handleUpload}
                                    listType="picture-card"
                                    fileList={fileList}
                                    // fileList={uploadFileList.current}
                                    beforeUpload={handleBeforeUpload}
                                    onPreview={handlePreview}
                                    onChange={handleChange}
                                    // onRemove={handleRemove}
                                >
                                    {fileList.length >= 1 ? null : uploadButton}
                                    {/* {uploadFileList.current.length >= 1 ? null : uploadButton} */}
                                </Upload>
                                <Modal
                                    // visible={previewVisible}
                                    open={previewVisible}
                                    title={previewTitle}
                                    footer={null}
                                    onCancel={handleCancel}
                                >
                                    <img alt="profile" style={{ width: '100%' }} src={previewImage}/>
                                </Modal>
                                <div id="signup_image_upload_msg_div" className="signup_msg_div">
                                    {imageMessage}
                                </div>
                            </div>
                            <Divider/>
                            <div id="signup_button_container_div">
                                {/* <Button htmlType="submit" type="primary">제출</Button> */}
                                {/* <Button type="primary" onClick={onSubmitHandler} disabled={!agreeOnTOC}>제출</Button> */}
                                <Button type="primary" onClick={onSubmitHandler} disabled={true}>제출</Button>
                                <Button onClick={onCancelHandler}>홈으로</Button>
                            </div>
                        </form>
                    </div>
                }
                {!isLoggedIn && signupComplete &&
                    <div id="signupcomplete_top_container_div">
                        <div id="signupcomplete_msg_container_div">
                            등록이 완료되었습니다!
                        </div>
                        <div id="signupcomplete_button_div">
                            <Button type="primary" onClick={onHandleSignin}>Signin</Button>
                            <Button onClick={onCancelHandler}>홈으로</Button>
                        </div>
                    </div>
                }
            </div>
            {/* <div>
                {resizedImage}
            </div> */}
            {!isLoggedIn && !signupComplete &&
                <div id="signup_terms_of_contract_div">
                    {/* <Collapse>
                        <Panel header="서비스 이용 약관" key={0}>
                            {termsE}
                        </Panel>
                    </Collapse> */}
                    <Collapse items={signupTermsOfContractItems}/>
                </div>
            }
            {!isLoggedIn && !signupComplete &&
                <div id="signup_terms_of_contract_checkbox_div">
                    <Checkbox defaultChecked={false} onChange={onClickTermsOfContractCheckbox}>이용 약관에 동의합니다.</Checkbox>
                </div>
            }
            {!isLoggedIn && !signupComplete &&
                <div id="signup_privacy_policy_div">
                    {/* <Collapse>
                        <Panel header="개인정보 보호정책 (공지)" key={0}>
                            {policyE}
                        </Panel>
                    </Collapse> */}
                    <Collapse items={signupPrivacyPolicyItems}/>
                </div>
            }
       </AppLayout>
    );
}

export default Signup;
